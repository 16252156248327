
const AppSettings = {
  appTitle: 'Pollokshaws',
  siteAdmin: '',
  baseUrl: 'https://europe-west2-pinpoint-pollokshaws.cloudfunctions.net/functions/',
  showFeedbackButton: false,
  showPostcodeFilter : false,
  showHelp: false,
  showUser: true,
  enableFilter: false,
  enableEntitySearch: false,
  enableChildrenCount: false,
  enableEntityPopup: true,
  parentChange: false,
  feedbackType: 'form',
  showMapKey: true,
  sateliteView: true,
  layerVisibilityToggle: false,
  imageMapKey: 'https://firebasestorage.googleapis.com/v0/b/pinpoint-pollokshaws.appspot.com/o/Toggle-key_v2-02.jpg?alt=media&token=528b9095-4e81-4b68-9c05-cc743bdb7830',
  mapConfig: {
      accessToken:
        "pk.eyJ1IjoiY3JlYXRpdmUtZGVzaWduIiwiYSI6ImNqYXY0d3h2eTZ5M2syd2w5eWo3Ymd5bGIifQ.cYdELtP5xXELeUZ0p_cS_g",
      templatename: "mapbox://styles/creative-design/ckeshnbzh7sai19qqc9gu3nnm/draft", ///draft", //"mapbox://styles/mapbox/light-v10", //"mapbox://styles/mapbox/basic-v9?optimize=true",
      startLocation:[-4.30446, 55.82476],//[-0.457619, 51.4712667],
      startZoom: 14.6,
      startPitch: 0

    },

}; 

export default AppSettings;

