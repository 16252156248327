<template>
  <section id="header-panel">
    <nav>
      <div class="nav-wrapper">
        <Branding :logo="{ img: logo, alt: 'GCC', width: 136 }" >
          <ul class="navigation" v-if="rootTypes && rootTypes.length > 1">
            <li v-for="item in rootTypes" :key="item.index">
              <router-link :to="'/' + item.type + '/'" :class="{active: activeInstance == item.type  ? true : false}">{{item.displayName}}</router-link>
            </li>
          </ul>
        </Branding>
      </div>
    </nav>

    <div class="help">
      <router-link :to="'/help'" v-if="showHelp">
        <i class="material-icons">help_outline</i>
      </router-link>

      <a v-if="showUser" href @click="logout" :title="'Current user ' + username + ' Click to logout'">
        <i v-if="username" class="material-icons">person</i>
      </a>
    </div>
  </section>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";

export default {
  name: "Header",
  components: {
    Branding
  },
  data() {
    const user = firebase.auth().currentUser;

    return {
      hideButton: true,
      username: "",
      appTitle: Settings.appTitle,
      activeInstance: "",
      showHelp: Settings.showHelp,
      showUser: Settings.showUser,
      logo: Logo
    };
  },
  mounted() {
    let user = firebase.auth().currentUser;

    if (user) {
      //console.log(user);
      this.username = user.email;
    }

    var elems = document.querySelectorAll(".dropdown-trigger");
    var instances = M.Dropdown.init(elems, {});
  },
  methods: {
    add: function() {
      this.$router.push("/add");
    },
    logout: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("setUserData", "");
          this.$store.commit("setCurrentInstance", "");
          this.$router.push("/login");
        })
        .catch(function(error) {
          alert("Ops " + error.message);
        });
    }
  },
  computed: {
    ...mapGetters(["user","allEntityTypes"]),
    rootTypes: function() {
      if( this.allEntityTypes  && this.user)
      {
        let rootTypes = this.allEntityTypes.filter((item)=> {return item.parent.length == 0})
        let foundTypes = []
      
        for(let item of rootTypes)
        {
          if(item.requiredRole == "*")
          {
              foundTypes.push(item)
          }
          else
          {
            if(item.requiredRole.indexOf(this.user.role) != -1 )
            {
                foundTypes.push(item)
            }
          }
        }

        return  foundTypes
      }
    }
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    }
  }
};
</script>
