<template>
  <pre
    contenteditable="true"
    v-once
    v-html="syntaxHighlight(value)"
    :value="value"
    @input="$emit('input', $event.target.innerText)"
    @paste="onPaste"
  ></pre>
</template>

<script>
export default {
  name: "Editable",
  props: ["value"],
  data() {
    return { message: "",
    content: "" };
  },
  mounted() {},
  methods: {
    onPaste(e) {
   
      e.preventDefault();
   
      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");

      // insert text manually
      document.execCommand("insertHTML", false, text);
    },
    syntaxHighlight(json) {

      
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function(match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    }
  },
  watch: {
    value: function(newValue) {
      if (document.activeElement == this.$el) {
        return;
      }

      this.$el.innerHTML = newValue;
    }
  }
};
</script>
