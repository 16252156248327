<template>
  <div class="site-view">
    <section class="site-hero">
      <div class="hero">
        <div class="container">
          <div class="row">
            <div class="col-flex">
              <header :class="{ 'main-nav--open': navOpen }">
                <div class="header">
                  <Navigation/>
                  <div>
                    <p class="sub-logo">#CONNECTSHAWS</p>
                    <a href="#" class="menu" @click="navOpen = !navOpen">
                      <div id="nav-burger">
                        <div class="burger-box">
                          <span></span><span></span><span></span>
                        </div>
                      </div>
                    </a>
                    <div class="logo" v-html="appName"></div>
                  </div>
                </div>
              </header>
              <!-- <div class="logo"></div> -->
              <!-- <img class="sub-logo" src="@/assets/images/pollokshaws/connectshaws.svg" alt=""> -->
              <p class="sub-logo">#CONNECTSHAWS</p>
<!--              <img
                  class="main-sub-logo"
                  src="@/assets/images/pollokshaws/ConnectingLogoBlue.svg"
              />-->
              <img class="main-sub-logo" src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg"  />
              <!-- <img class="wrapper-logo" src="@/assets/images/pollokshaws/Shaws-Logo.svg" alt=""> -->
              <div class="img-wrapper">
                <img src="@/assets/images/pollokshaws/Shaws-Logo.svg" alt=""/>
              </div>
              <!-- <div class="site-btn site-btn-yellow">
                <router-link to="/map/entity/gnzMtAZnfEwWjsUW3IHX/addentity/feedback">
                  <span>Shaws</span>
                  Your thoughts
                  <div class="arrow"><i class="fas fa-caret-right"></i></div>
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-cta">
      <div class="container">
        <div class="row">

          <div class="col-flex">
            <div class="cta-card cta-card-pink">
              <div class="content">
                <h2>Stage 1</h2>

                <p>Find out more about the project and what we have been up to with pupils from St Conval's Primary.</p>



<!--                <div class="site-btn site-btn-yellowSpace">
                  <router-link to="/site/findings">
                    <span class="whiteBG">Shaws</span>What Matter findings
                    <div class="arrow"><i class="fas fa-caret-right"></i></div>
                  </router-link>
                </div>-->
                <div class="site-btn site-btn-yellowSpace">
                  <a href="https://firebasestorage.googleapis.com/v0/b/pinpoint-pollokshaws.appspot.com/o/Pollokshaws_Stage1Findings_v2.pdf?alt=media&token=967a1619-24f6-4146-8a28-2924e2e88623"
                     target="_blank">
                    <span class="whiteBG">Shaws</span>What Matter findings
                    <div class="arrow"><i class="fas fa-caret-right"></i></div>
                  </a>
                </div>

                <div class="space"></div>

                <div class="site-btn site-btn-yellow">
                  <a href="https://firebasestorage.googleapis.com/v0/b/pinpoint-pollokshaws.appspot.com/o/Newsletter-Nov2020.pdf?alt=media&token=65fe7138-7fcd-44b7-8220-0912099de9db"
                     target="_blank">
                    View Newsletter
                    <div class="arrow"><i class="fas fa-caret-right" aria-hidden="true"></i></div>
                  </a>
                </div>


              </div>
            </div>
          </div>

          <div class="col-flex">
            <div class="cta-card cta-card-blue">
              <div class="content">
                <h2>Stage 2 <br/> Shaws Your Thoughts</h2>
                <!-- <p>Complete the <b>'Shaws Your Ideas'</b> survey and find out more about stage 2 of the project.</p> -->
                <p> Tell us your thoughts on the concept designs.</p>

                <div class="site-btn site-btn-yellowSpace">
                  <router-link to="/map/entity/gnzMtAZnfEwWjsUW3IHX/addentity/feedback">
                    <span class="whiteBG">Complete</span>
                    the survey
                    <div class="arrow"><i class="fas fa-caret-right"></i></div>
                  </router-link>
                </div>

                <!--                <div class="site-btn site-btn-yellowSpace">
                                  <a href="" target="_blank">
                                    Complete survey
                                    <div class="arrow"><i class="fas fa-caret-right" aria-hidden="true">
                                  </i>
                                  </div>
                                  </a>
                                </div>-->

                <div class="space"></div>

                <div class="site-btn site-btn-yellow">
                  <a href="https://firebasestorage.googleapis.com/v0/b/pinpoint-pollokshaws.appspot.com/o/pollokPlans%2FPresentation%20Boards%201-5%20combined.pdf?alt=media&token=35274431-cd7f-4f38-a6fe-01d1601f2eb8"
                     target="_blank">
                    View our Concept Design Boards
                    <div class="arrow"><i class="fas fa-caret-right" aria-hidden="true">
                    </i>
                    </div>
                  </a>
                </div>


              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="site-text-single-col">
      <div class="container">
        <div class="row">
          <div class="col s12 m12 l12">
            <h2>WE WANT TO MAKE POLLOKSHAWS A GREAT PLACE</h2>
            <p>
              Connecting Pollokshaws aims to design collaboratively with the
              local community to enhance the streets, paths, parks and places in
              Pollokshaws to promote health and well-being and recreate a sense
              of pride in Pollokshaws rich heritage
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="site-video">
      <div class="container">
        <div class="row">
          <div class="col s12 m12 l12">
            <div class="video-wrapper">
              <div class="iframe-wrapper">
                <iframe
                    src="https://player.vimeo.com/video/463527504"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-cards-3col">
      <div class="container">
        <div class="row">
          <div class="col s12 m12 l12">
            <h2>CONNECTING POLLOKSHAWS AIMS TO</h2>
          </div>
        </div>
        <div class="row-flex">
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img src="@/assets/images/pollokshaws/Heritage.jpg" alt=""/>
                </div>
                <div class="cp_card--text">
                  <p>
                    Enhance the look and feel of the area by creating a sense of
                    ownership and pride in Pollokshaws rich heritage
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img
                      src="@/assets/images/pollokshaws/Website_assets2.png"
                      alt=""
                  />
                </div>
                <div class="cp_card--text">
                  <p>
                    Improve pedestrian and cycle connections within Pollokshaws
                    and develop linkages to surrounding areas
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img src="@/assets/images/pollokshaws/Roads.jpg" alt=""/>
                </div>
                <div class="cp_card--text">
                  <p>
                    Reduce car dominance and introduce measures to improve road
                    safety for all users
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img
                      src="@/assets/images/pollokshaws/Website_assets3.png"
                      alt=""
                  />
                </div>
                <div class="cp_card--text">
                  <p>
                    Create attractive inclusive enjoyable streets and spaces for
                    people of all ages and abilities
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img
                      src="@/assets/images/pollokshaws/Website_assets5.png"
                      alt=""
                  />
                </div>
                <div class="cp_card--text">
                  <p>
                    Engage residents, businesses, children & the wider community
                    in shaping their neighbourhood
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img
                      src="@/assets/images/pollokshaws/Apprentice.jpg"
                      alt=""
                  />
                </div>
                <div class="cp_card--text">
                  <p>
                    Promote opportunities for development that can drive
                    investment and future growth in the area
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div class="home-news-banner">
            <div class="content">
              LATEST <span>NEWS AND UPDATES</span> ON THE PROJECT
            </div>
            <a href="https://www.facebook.com/cpollokshaws" target="_blank"> READ MORE
              <div class="arrow"><i class="fas fa-caret-right" aria-hidden="true"></i></div>
            </a>
          </div>

        </div>
      </div>
    </section>
    <section class="site-cards-4col">
      <div class="container">
        <div class="row">
          <div class="col s12 m12 l12">
            <h2>WHO WE ARE</h2>
          </div>
        </div>
        <div class="row-flex">
          <div class="col-flex">
            <router-link to="/site/aboutPage">
              <div class="wrapper">
                <div class="cp_card">
                  <div class="cp_card--image">
                    <img src="@/assets/images/pollokshaws/robert.png" alt="">
                  </div>
                  <div class="cp_card--text">
                    <p>
                      ROBERT <br/>MCKINNON
                      <br/>
                      <span>Service Manager, Pollokshaws After School Service</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-flex">
            <router-link to="/site/aboutPage">
              <div class="wrapper">
                <div class="cp_card">
                  <div class="cp_card--image">
                    <img src="@/assets/images/pollokshaws/Amy.png" alt="">
                  </div>
                  <div class="cp_card--text">
                    <p>
                      AMY <br/>WIMPENNY
                      <br/>
                      <span>Regeneration Co-ordinator, Wheatley Group</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <!--          <div class="col-flex">
                      <router-link to="/site/aboutPage">
                      <div class="wrapper">
                        <div class="cp_card">
                          <div class="cp_card&#45;&#45;image">
                            <img src="@/assets/images/pollokshaws/Dan.png" alt="">
                          </div>
                          <div class="cp_card&#45;&#45;text">
                            <p>
                              DAN <br />JEFFS
                              <br />
                              <span>Community Engagement and Urban Designer, Sustrans</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      </router-link>
                    </div>-->
          <div class="col-flex">
            <router-link to="/site/aboutPage">
              <div class="wrapper">
                <div class="cp_card">
                  <div class="cp_card--image">
                    <img src="@/assets/images/pollokshaws/ujwala.png" alt="">
                  </div>
                  <div class="cp_card--text">
                    <p>
                      UJWALA <br/>FERNANDES
                      <br/>
                      <span>Associate Landscape Architect, Atkins</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col-flex">
            <router-link to="/site/aboutPage">
              <div class="wrapper">
                <div class="cp_card">
                  <div class="cp_card--image">
                    <img src="@/assets/images/pollokshaws/victoria.png" alt="">
                  </div>
                  <div class="cp_card--text">
                    <p>
                      VICTORIA<br/> MCLEAN
                      <br/>
                      <span>Teacher, St Conval’s Primary School</span>
                    </p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="site-logos site-logos--blue">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex col-flex-grow">
            <img
                src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg"
                alt=""
            />
          </div>
          <!-- <div class="col-flex">
            <img class="main-logo" src="@/assets/images/pollokshaws/Original_blue-text.svg" alt="">
          </div>
          <div class="col-flex">
            <img src="@/assets/images/pollokshaws/sustrans.png" alt="">
          </div>
          <div class="col-flex">
            <img src="@/assets/images/pollokshaws/school-logo.png" alt="">
          </div> -->
          <div class="col-flex">
            <a href="https://www.facebook.com/cpollokshaws" target="_blank">
              <img src="@/assets/images/pollokshaws/Facebook2.png" alt=""/>
            </a>
          </div>
          <div class="col-flex">
            <a href="https://twitter.com/CPollokshaws" target="_blank">
              <img src="@/assets/images/pollokshaws/Twitter2.png" alt=""/>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="site-logos site-logos--footer">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex">
            <img
                class="shaw-logo"
                src="@/assets/images/pollokshaws/Shaws-Logo-Blue.svg"
                alt=""
            />
          </div>
          <div class="col-flex col-flex-grow">
            <div class="logo-wrapper">
              <div class="logo-row">
                <div class="logo-col">
                  <a href="https://www.wheatley-group.com/" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/WHG-Logo-CMYK-coated.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.sustrans.org.uk/" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/sustrans-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.transport.gov.scot/ " target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/transport-scotland-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a
                      href="https://www.facebook.com/Pollokshawsafterschoolservice/"
                      target="_blank"
                  >
                    <img
                        src="@/assets/images/pollokshaws/logos/pass-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a
                      href="https://blogs.glowscotland.org.uk/gc/stconvalsprimaryschool/"
                      target="_blank"
                  >
                    <img
                        src="@/assets/images/pollokshaws/logos/st-convals-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://glasgow.gov.uk/home" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/glasgow-city-council-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.atkinsglobal.com" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/atkins-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p class="copyright">
            Copyright Ⓒ Connecting Pollokshaws 2022 | All rights reserved | Site
            by Atkins Creative Design
          </p>
        </div>
      </div>
    </section>

    <!-- overlay -->
    <div class="modal-vue" v-if="showModal">
      <!-- modal -->
      <div class="modal-contact" v-if="showModal">
        <button class="close" @click.prevent="showModal = false">
          <i class="fas fa-times"></i>
        </button>
        <div class="row contactForm">
          <h2>Contact Form</h2>
          <form class="contact-form" @submit.prevent="submitForm">
            <input
                label="Your name"
                v-model="fields.name"
                placeholder="Your Name"
                required
            />
            <input
                type="email"
                label="Your email"
                v-model="fields.email"
                placeholder="Email"
                required
            />
            <textarea
                label="Address"
                v-model="fields.address"
                placeholder="Address"
                required
                class="materialize-textarea"
            ></textarea>
            <input
                label="Postcode"
                v-model="fields.postcode"
                placeholder="PostCode"
                required
            />
            <div class="site-btn site-btn-yellow">
              <button>
                SEND MESSAGE
                <div class="arrow"><i class="fas fa-caret-right"></i></div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Apicall from "@/mixins/Apicall";
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import {mapState, mapGetters} from "vuex";
import Navigation from "@/components/customPages/navigation.vue";
import SiteFooter from "@/components/customPages/components/footer.vue";
import SiteNav from "@/components/customPages/components/siteNav.vue";
// import novPdf from "@/assets/images/docs/Newsletter-Nov2020.pdf"
import _ from "lodash";

const Stickyfill = require("stickyfilljs");
import MainLogo from "@/assets/images/pollokshaws/Shaws-Logo.svg";

export default {
  name: "homePage",
  components: {
    SiteFooter,
    SiteNav,
    Navigation,
  },
  data() {
    return {
      MainLogo: MainLogo,
      fields: {
        name: "",
        email: "",
        address: "",
        postcode: "",
        message: "",
      },
      navOpen: false,
      showModal: false,
    };
  },
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
  },
  async mounted() {
    // this.populateTimeline();
    if (this.entities.length == 0) {
      await this.$store.dispatch("populateEntities");
    }
  },
  methods: {
    async submitForm(event) {
      var messageBody = "";

      for (let item in this.fields) {
        messageBody += "<p>" + item + ":" + this.fields[item] + "</p>";
      }

      let bodyData = {
        subject: "Contact Information Pollokshaws Contact Form",
        messageBody: messageBody,
      };
      let results = await Apicall.submitForm(bodyData);
      if (results.status == "200") {
        M.toast({
          html: "Message sent, thank you.",
          classes: "blue",
        });

        this.fields = {
          name: "",
          email: "",
          address: "",
          postcode: "",
          message: "",
        };

        this.showModal = false;
        console.log("Submitted");
      } else {
        M.toast({
          html: "Something went wrong. Please try again",
          classes: "error",
        });
        console.log("Please try submitting again");
      }
    },
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    },
  },
};
</script>
