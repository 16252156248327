<template>
  <div id="overview-component">
    <div class="sticky-heading">
      <h2 v-if="currentEntity && user">
        {{ currentEntity.name }}  
        <button
          v-if="currentEntity && user.role == 'Admin'"
          @click.prevent="edit()"
        >Edit</button> 
      </h2>
      
      <button class="backButon" v-if="history.length > 1" @click.prevent="historyBack()">
        <i class="material-icons">arrow_back</i>
        {{$t('text.back')}}
      </button>
    </div>

    <div class="addFilter" v-if="showFilter">
      <h1>+ Add filter</h1>
      <h2>Add filter the data</h2>

      <div>
        <h3>Entity Type</h3>
        <select v-model="filterEntity">
          <option
            v-for="option in filterEntities"
            :key="option.index"
            :value="option.type"
          >{{option.displayName}}</option>
        </select>
      </div>
      <div>
        <h3>Entity fields</h3>
        <select v-model="filterField">
          <option
            v-for="option in filterFields"
            :key="option.index"
            :value="option.value"
          >{{option.displayName}}</option>
        </select>
      </div>
      <div>
        <h3>Operator</h3>
        <select v-model="filterOperator">
          <option selected>=</option>
          <option>!=</option>
          <option>Contains</option>
        </select>
      </div>
      <div>
        <h3>Value</h3>
        <div>
          <input class="input-field" type="text" v-model="filterValue" />
        </div>
      </div>
      <div class="bottom">
        <button @click.prevent="toggleFilter(false)">Cancel</button>
        <button @click.prevent="updateFilter()">Add Filter</button>
      </div>
    </div>

    <div class="breadcrumbs">
      <div class="breadcrumbs-left">
        <ul v-if="currentEntity">
          <li v-for="historyItem in history" :key="historyItem.item.docId">
            <a href="#" @click.prevent="goToNode(historyItem.url)">{{historyItem.item.name}}</a>
          </li>
        </ul>
      </div>
      <div class="breadcrumbs-right" v-if="currentEntity">
        <button
          v-if="showFeedbackButton"
          class="embedded-btn"
          @click.prevent="openFeedback()"
        >Leave feedback on: {{currentEntity.name}}</button>
        <button
          v-if="feedbackCount != 0"
          v-bind:class="[{ tada: wiggleFeedback },'animated' ,'embedded-btn-submit']"
          @click.prevent="addFeedback()"
        >Submit Feedback ({{feedbackCount}})</button>
      </div>
    </div>

    <div class="offset-buttons">
      <div class="buttons-left">
        <!-- <button class="btn btn-secondary" @click.prevent="historyBack()">{{ goBacklbl }}</button> -->
        <div v-if="showPostcodeFilter" class="search-form">
          <form @submit.prevent="getPostcodeSearch(searchBy)">
            <div class="sb-term">
              <input v-model="searchBy" type="search" placeholder="Search for your Post Code" />
            </div>
            <span
              class="search-icon"
              :class="{ show : hideClear }"
              @click.prevent="getPostcodeSearch(searchBy)"
            >
              <i class="material-icons">search</i>
            </span>
            <span class="search-icon load-wrap" v-show="searchStatus">
              <div id="loading"></div>
            </span>
            <span class="search-icon" :class="{ show : !hideClear }" @click.prevent="clearInput()">
              <i class="material-icons">clear</i>
            </span>
          </form>
          <span
            v-if="searchResults.length != 0"
            class="help"
          >Click on result to show nearby interventions</span>
          <ul class="results" v-if="searchResults && searchResults.length != 0">
            <li v-for="result in searchResults" :key="result.place_id">
              <a @click.prevent="clearPostCodeResults();findEntities(result)" href="#">
                <div>{{ result.display_name }}</div>
              </a>
            </li>
          </ul>
          <span
            v-if="entitiesByPostCode.length != 0"
            class="help"
          >Click on result to show intervention</span>
          <ul class="results" v-if="entitiesByPostCode && entitiesByPostCode.length != 0">
            <li v-for="result in entitiesByPostCode" :key="result.docId">
              <a @click.prevent="navigate(result)" href="#">
                <div>{{ result.name}} ({{result.distance}} - miles)</div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="buttons-right"></div>
    </div>

    <div class="layout-wrap" :style="{ height: currentEntityTypesEdit.length == 0 ? '100%' : false }">
      <div class="overview-body">
        <div class="overview-body-wrap" v-if="currentEntity">
          
          <div class="heading">
            <h2 v-if="currentEntity.parent != 'null' && user">
              {{ currentEntity.name }} 
              <button
                v-if="currentEntity && entityTypeByType(currentEntity.type)[0] ? entityTypeByType(currentEntity.type)[0].requiredRole.indexOf(user.role) != -1 : false"
                @click.prevent="edit()"
              >Edit</button> 
            </h2>


            <button class="backButon" v-if="history.length > 1" @click.prevent="historyBack()">
              <i class="material-icons">arrow_back</i>
              {{$t('text.back')}}
            </button>
          </div>
          <hr />
          <div class="filters" v-if="enableFilter">
            <button @click.prevent="toggleFilter(true)">+ Add filter</button>
            <div>
              <ul>
                <li v-for="(filter,i) in filters" :key="i">
                  {{convertFilterToText(filter)}}
                  <button @click="clearFilterItem(filter)">
                    <i class="material-icons">close</i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
         
          <input class="filter filter--inline" v-model="inlineFilterData" type="text" v-if="childEntities.length > 0 && showFilter" :placeholder="'Filter..'" />
          <div v-for="field in formFields" :key="field.name" class="dynamic-collection"> 
            <div
              v-if="field.type == 'string' || field.type == 'dropdown' || field.type == 'id' "
              class="text-collection"
            >
            <button v-if="field.value=='yes' && field.type == 'dropdown'"  @click.prevent="openFeedback()"> {{field.displayName}} </button>
              <p class="desc" v-if="field.value!='yes' && field.type != 'dropdown'">
                <strong>{{field.displayName}}:</strong>
                {{field.value}}
              </p>
            </div>
            <div v-if="field.type == 'date'" class="text-collection">
              <p class="desc">
                <strong>{{field.displayName}}:</strong>
                {{field.value | formatDate}}
              </p>
            </div>
            <div v-else-if="field.type == 'html'" class="html-collection">
              <div class="html-collection-heading">
                <strong>{{field.displayName}}</strong>
              </div>
              <div class="html-collection-body" v-html="field.value"></div>
              <!-- Readmore -->
              <div v-show="hideDesc == true" class="hideDescReadMore">
                <a href="#" @click.prevent="toggleReadMore()">{{ hideDescText }}</a>
              </div>
            </div>

            <div v-else-if="field.type == 'textarea'" class="textarea-collection">
              <div>
                <strong>{{field.displayName}}:</strong>
              </div>
              <label :for="field.name">{{field.value}}</label>
            </div>

            <div v-if="field.userLink" class="user-link">
               <div>
                <strong>Created By:</strong> <router-link :to="userLink(currentEntity.createdBy,'Link')">{{userLink(currentEntity.createdBy,'UserName')}}</router-link>
              </div>
            </div>

            <div v-else-if="field.type == 'checkbox'" class="bullet-listing">
              <div>
                <strong>{{field.displayName}}:</strong>
              </div>
              <ul> <li v-for="(item,i) in field.value" :key="i">{{getNameValue(item)}}</li></ul>
            </div>

            <div v-else-if="field.type == 'files'" class="files-collection">
              <strong>{{field.displayName}}</strong>
              <div>Click on icon to show file</div>
              <div class="file-collection">
                <div v-for="(item,i) in field.value" :key="i">
                  <a 
                    v-if="item.type == 'image/jpeg' ||  item.type == 'image/png'"
                    :href="item.url"
                    target="_blank"
                    :title="item.name"
                    :data-ext="getFileExtension(item.name)"
                    @click.prevent="renderDocType(item)"
                  >
                    <!-- <img width="120" height="120" :src="getThumbnailURL(item.url)" /> -->
                    <span class="preview"></span>
                    <span class="name">{{item.name}}</span>
                  </a>
                  <a 
                    v-else
                    :href="item.url"
                    :title="item.name"
                    :data-ext="getFileExtension(item.name)"
                  >
                    <!-- <img width="120" height="120" :src="getThumbnailURL(item.url)" /> -->
                    <span class="preview"></span>
                    <span class="name">{{item.name}}</span>
                  </a>
                </div>
              </div>
            </div>
            <div v-else-if="field.type == 'video'">
              <!-- <ViewerVideo :video="field.value.url" /> -->
              <!-- <div v-if="field.value.autoplay == false">
              Video play button to be implemented
              </div>-->
            </div>

            <div v-else-if="field.type == 'entityLink'">
              <div class="entity-link">
                <router-link :to="field.value.url">{{field.value.name}}</router-link>
              </div>
            </div>
            <div v-else-if="field.type == 'geojson'"></div>

            <!-- <div v-else>
              <p class="desc">{{field.value}}</p>
            </div>-->
          </div>

          <div 
            :class="childEntities.length == (i+1) ? 'overview-block overview-Block-end' : 'overview-block' "
            v-for="(group,i) in childEntities"
            :key="i" 
          >
            <div class="field-heading" v-if="group.states != 0" >
              <h1 class="underline">{{group.type.displayName}}s</h1>
              <p class="dynamic-subfield" v-if="group.type.subName">{{ group.type.subName }}</p>
            </div>
            <div class="external-button" v-if="group.type.backButton" v-html="group.type.backButton"></div>

            <div class="custom-accordion">
              <ul class="collapsible">
                <li v-for="(state,i) in group.states" :key="i" class="collapsible-item">
                  <!-- <div 
                    class="collapsible-header"
                    v-if="state.name != 'default'"
                    :class="{'active': activeAccord == state.name}"
                    @click="setActiveAccord(state.name)"
                  > -->
                  <div 
                    class="collapsible-header"
                    @click="setActiveAccord(state.name)"
                    v-if="state.name != 'default'"
                  >
                    <span>{{ state.children.length }}</span>{{state.name}}
                  </div>
                  <div class="collapsible-body" :class="{'showDefault': state.name == 'default'}">
                    <div class="location-cards" v-if="activeAccord == state.name || state.name == 'default'">
                      <div class="location-card location-card--visibility-toggle" v-for="(item,i) in state.children" :key="i">
                      
                        <a v-on:click="navigate(item)">
                          <div
                            class="wrapper"
                            :style="{ borderLeftColor: item.properties.entityColor}"
                          >
                            <div class="left">
                              <h2 :class="{'mb-1': notEmptyObject(item.children) > 0 }">{{item.name}}</h2>
                             <h4 v-if="item.properties.subName">{{item.properties.subName}}</h4>

                              <div v-if="notEmptyObject(item.children) > 0 && enableChildrenCount">
                                <span v-for="(value,i) in item.children" :key="i">
                                  <strong>{{(entityTypeByType(i)[0] == undefined) ? '' : entityTypeByType(i)[0].displayName}}</strong>
                                  : {{value.length}} &nbsp;
                                </span>
                              </div>
                            </div>
                            <div class="buttons" :class="{'center-btns': !enableLayerVisibilityToggle}">
                              <div class="buttons-wrapper">
                                <i class="material-icons">keyboard_arrow_right</i>
                              </div>
                            </div>
                          </div>
                        </a>
                        <div class="show-layer" v-if="enableLayerVisibilityToggle">
                          <label :for="'checbox-'+i">
                            <input v-if="item.containsMapElements" type="checkbox" :id="'checbox-'+i" @change="updateShowObMapStatus(item)" v-model="item.showOnMap">
                            <span v-if="item.containsMapElements" >
                              <svg class="layer-show" aria-hidden="true" focusable="false" data-prefix="far" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#000" d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z"></path></svg>
                              <svg class="layer-hide" aria-hidden="true" focusable="false" data-prefix="far" data-icon="eye-slash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#000" d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"></path></svg>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- <div v-for="(state,i) in group.states" :key="i" class="has-accordion">
              <div v-if="state.children" class="custom-accordion">
                <ul class="collapsible">
                  <div
                    v-if="state.name != 'default'"
                    class="collapsible-header"
                    :class="{'active': activeAccord == state.name}"
                    @click="setActiveAccord(state.name)"
                  ><span>{{ state.children.length }}</span>{{state.name}}</div>
                  <div v-if="activeAccord == state.name || state.name == 'default'">
                    <li v-for="(item,i) in state.children" :key="i">
                      <a v-on:click="navigate(item)">
                        <div
                          class="wrapper"
                          :style="{ borderLeftColor: item.properties.entityColor}"
                        >
                          <div class="left">
                            <h2 :class="{'mb-1': notEmptyObject(item.children) > 0 }">{{item.name}}</h2>
                            <div v-if="notEmptyObject(item.children) > 0 && enableChildrenCount">
                              <span v-for="(value,i) in item.children" :key="i">
                                <strong>{{(entityTypeByType(i)[0] == undefined) ? '' : entityTypeByType(i)[0].displayName}}</strong>
                                : {{value.length}} &nbsp;
                              </span>
                            </div>
                          </div>
                          <div class="right"></div>
                        </div>
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div> -->
          </div>

          <div
            v-if=" entity != undefined "
            :class="[currentEntityTypesEdit.length <= 1 ? 'add-pin hiddenDropdown' : 'add-pin']"
            :style="{ display: currentEntityTypesEdit.length == 0 ? 'none' : 'flex' }"
          >
            <!-- <h2>Add new record</h2> -->
            <select v-model="selectedAddType">
              <option
                v-for="item in currentEntityTypesEdit"
                :key="item.type"
                :value="item.type"
              >{{item.displayName}}</option>
            </select>
            <button v-if="currentEntityTypesEdit.length > 0" @click="add()">Add {{selectButtonText}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="render render-pdf" v-if="pdfShow && pdfViewerUrl">
      <ul class="controls">
        <li>
          <button class="close" @click="closePdfViewer()">
            <i class="material-icons">close</i>
          </button>
        </li>
      </ul>
      <div
        v-if="pdfLoadedRatio > 0 && pdfLoadedRatio < 1"
        style="background-color: green; color: white; text-align: center"
        :style="{ width: pdfLoadedRatio * 100 + '%' }"
      >{{ Math.floor(loadedRatio * 100) }}%</div>

      <div class="wrap">
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="pdfViewerUrl"
          :page="i"
          style="display: block; width: 100%; max-width: 1280px; margin: 40px auto;"
          @progress="pdfLoadedRatio"
        ></pdf>
      </div>
    </div>

    <div class="render render-image" v-if="imgShow && imgViewerUrl">
      <ul class="controls">
        <li>{{imgViewerName}}</li>
        <li>
          <button class="close" @click="closeImgViewer()">
            <i class="material-icons">close</i>
          </button>
        </li>
      </ul>

      <panZoom :options="{minZoom: 0.5, maxZoom: 5, contain: 'inside', startScale: 0}">
        <img :src="imgViewerUrl" alt />
      </panZoom>
    </div>

    <ViewerVideo v-if="videoEntity" :video="currentEntity.properties.video.url" />

    <MapKey />
    <!-- <SatView /> -->

  </div>
</template>

<script>
import Vue from "vue";
import Settings from "@/mixins/AppSettings.js";
import { store } from "../store";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import pdf from "vue-pdf";
const mapboxgl = require("mapbox-gl");
import * as turf from "@turf/turf";
import moment from "moment";

// import vue-panzoom
import panZoom from "vue-panzoom";
Vue.use(panZoom);
import ViewerVideo from "@/components/viewers/Video";
import ReadMoreToggle from "@/mixins/ReadMoreToggle.js";
import MapKey from "@/components/MapKey";
import SatView from "@/components/SateliteViewToggle";

export default {
  name: "EntityOverview",
  mixins: [ReadMoreToggle],
  components: {
    pdf,
    ViewerVideo, 
    MapKey,
    SatView
  },
  filters: {
    formatDate: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    }
  },
  data() {
    return {
      selectedAddType: undefined,
      feedbackType: Settings.feedbackType,
      filterEntity: null,
      filterField: null,
      filterValue: null,
      filterOperator: "=",
      showFilter: false,
      enableFilter: Settings.enableFilter,
      enableChildrenCount: Settings.enableChildrenCount,
      entity: "",
      goBacklbl: "Back to Scheme",
      from:'',
      pdfShow: false,
      pdfViewerUrl: "",
      numPages: undefined,
      pdfLoadedRatio: 0,
      imgShow: false,
      imgViewerUrl: "",
      imgViewerName: "",
      imgZoom: 1,
      videoShow: false,

      hideClear: false,
      searchBy: "",
      searchResults: [],
      entitiesByPostCode: [],
      searchStatus: false,
      syncNodes: false,

      showFeedback: false,
      feedbackOverview: false,
      wiggleFeedback: false,
      showFeedbackButton: Settings.showFeedbackButton,
      showPostcodeFilter: Settings.showPostcodeFilter,
      activeAccord: "",
      inlineFilterData: "",

      enableLayerVisibilityToggle: Settings.layerVisibilityToggle
    };
  },
  beforeRouteEnter(to, from, next) {
    
      if(Object.keys(to.query).length !== 0) { //if the url has query (?query)
        next(vm => {
          vm.from=from.path
       })
    }else{
      next(vm => {
          vm.from=from.path
       })
    }
    next()
  },
  beforeCreate() {
    //console.log("ROUTE");
  },
  created() {
    this.$root.$on("updateFeedbackVis", this.updateFeedbackVisibility);
  },
  // computed: {
    
  // },
  async mounted() {
    //console.log("loading site overview");

    if (this.$route.params.root == undefined) {
      this.$router.push("/site/homePage");
    }

    if (this.entities.length == 0) {
      await this.$store.dispatch("populateEntities");
    }

    // if (this.entityTypes.length == 0) {
    //   await this.$store.dispatch("populateEntityTypes");
      
    // }

    

    this.entity = this.currentEntity.docId;

    this.$root.$emit("refreshMap");

    //this.checkIfAdmin();

    this.$nextTick(() => {
      let elems = document.querySelectorAll(".collapsible");
      let instances = M.Collapsible.init(elems, {});

      let selectElems = document.querySelectorAll("select");
      let selectInstances = M.FormSelect.init(selectElems, {});
    });
  },

  methods: {
    updateShowObMapStatus(item)
    {
       this.$store.commit("updateEntity", item);
  
    },

    getNameValue(item){
      let survey = this.entityTypes.filter((survey) => { return survey.type == 'survey'}); 
      if(survey !=''){
        let res =  survey[0].fields.surveyType.values.filter((surveyType) => {return surveyType.value == item});
        return res[0].name;
      }
    },
    userLink: function (userEmail,displayType) {
      let user = this.entities.filter((user) => {return user.properties.email == userEmail});
      var nextPage =  "/" + "users/entity/" 
      var back="?" + "browseHistory=back"
      if(displayType=='UserName'){
        return user[0].name;
      }else if(displayType=='Link'){
        return nextPage+user[0].docId+back;
      }
        
    },
    setActiveAccord(name) {
      this.activeAccord = name;
    },
    isMobileDevice() {
      if (window.innerWidth < 1366) {
        return true;
      } else {
        return false;
      }
    },
    async search(params) {
      params.format = params.format || "json";
      if (params.countryCodesArray) {
        params.countrycodes = params.countryCodesArray.join(",");
      }
      if (params.accept_language) {
        params["accept-language"] = params.accept_language;
      }
      let url = new URL("https://nominatim.openstreetmap.org/search");

      Object.keys(params).forEach(key =>
        url.searchParams.append(key, params[key])
      );
      const header = {
        "Content-Type": "application/json;charset=UTF-8",
        Pragma: "no-cache"
      };
      const response = await this.$http.get(url.href, {
        headers: header
      });

      return response.data;
    },
    updateFeedbackVisibility(showFeedback, showSubmit) {
      this.showFeedback = showFeedback;
      this.feedbackOverview = showSubmit;
    },
    openFeedback() {
      this.showFeedback = true;
    },
    addFeedback() {
      this.showFeedback = true;
      this.feedbackOverview = true;
    },
    historyBack() {
      let lastHistoryItem = this.history[this.history.length - 2];
      
      if(this.$route.query.browseHistory=='user' || this.$route.query.browseHistory=='survey' || this.$route.query.browseHistory=='site'){
        this.$router.push(this.from);
      }else{
        this.$router.push(lastHistoryItem.url);
      }
      // let lastHistoryItem = this.history[this.history.length - 2];

      // if (this.history.length > 2) {console.log('test1')
      //   this.$router.push(lastHistoryItem.url);
      // } else {console.log('test2')
      //   this.$router.push("/" + this.currentRoot);
      // }
    },
    navigate(item) {
      let nextRoute = "/" + this.currentRoot + "/" + "entity/" + item.docId;
      this.$router.push(nextRoute);
    },
    goToNode(url) {
      this.$router.push(url);
    },
    getThumbnailURL(url) {
      let baseUrlArray = url.split("/");
      let baseUrl = url.replace(baseUrlArray[baseUrlArray.length - 1], ""); //Work out base URL
      url = url.replace(baseUrl, baseUrl + "thumbnails%2F"); //create new base url with thumnail folder

      let urlArray = url.split("?"); // strip off query string
      let frontPart = urlArray[0]; //Get front part
      urlArray = frontPart.split(".");

      let fileExt = urlArray[urlArray.length - 1];

      let frontPartWithoutExt = frontPart.replace("." + fileExt, "");

      url = frontPartWithoutExt + "_120x120." + fileExt + "?alt=media";
      return url;
    },
    getFileExtension(val) {
      if (!val) return "";

      const imgExt = ["png", "svg", "jpeg", "jpg"];
      const docExt = ["pdf", "doc", "docx"];

      
      val = val.toString().toLowerCase();

      let ext = val.split('.')[val.split('.').length-1];
     // let ext = val.substring(val.indexOf(".") + 1);

      if (imgExt.includes(ext)) {
        return "image";
      } else if (docExt.includes(ext)) {
        return "document";
      } else {
        return "placeholder";
      }
    },
    renderDocType(item) {
      const self = this;

      if (self.pdfShow) !self.pdfShow;

      //console.log(window);

      // Fetch extension of requested file.
      const ext = self.getFileExtension(item.name);

      // Check if PDF or Image.
      if (ext == "document") {
        self.pdfViewerUrl = pdf.createLoadingTask(item.url);
        self.pdfViewerUrl.then(pdf => {
          this.numPages = pdf.numPages;
          self.pdfShow = true;
        });
      } else if (ext == "image") {
        self.imgViewerUrl = "";
        self.imgViewerName = item.name;
        self.imgShow = true;
        self.$nextTick(function() {
          self.imgViewerUrl = item.url;
        });
      }

      // Append query string.
      // self.$router.push({ query: Object.assign({}, self.$route.query, { viewer: 'active' }) });
    },
    closePdfViewer() {
      this.pdfShow = false;
      this.pdfViewerUrl = "";
    },
    closeImgViewer() {
      this.imgShow = false;
      this.imgViewerUrl = "";
      this.imgViewerName = "";
    },
    clearPostCodeResults() {
      this.searchResults = [];
    },
    clearInput() {
      this.searchBy = "";
      this.hideClear = false;
      this.searchResults = [];
      this.entitiesByPostCode = [];
    },
    async getPostcodeSearch(query) {
      this.searchStatus = true;

      let results = await this.search({
        q: query,
        countrycodes: "gb"
      });

      this.searchStatus = false;

      this.hideClear = true;
      this.searchResults = results;
    },

    findEntities(location) {
      this.entitiesByPostCode = [];

      let postCodePoint = [location.lon, location.lat];
      for (let geojsonElement of this.geoJsonElements) {
        if (geojsonElement.geometry.type == "Point") {
          let distance = turf.distance(
            turf.point(postCodePoint),
            turf.point(geojsonElement.geometry.coordinates),
            { units: "miles" }
          );

          this.entitiesByPostCode.push({
            name: geojsonElement.properties.name,
            docId: geojsonElement.properties.docId,
            distance: Math.round(distance)
          });
        }
      }

      this.entitiesByPostCode = _.orderBy(
        this.entitiesByPostCode,
        "distance",
        "asc"
      );
    },
    panToLocation(location) {
      this.clearInput();
      this.$store.dispatch("setPostcodeSearch", {
        lat: location.lat,
        lon: location.lon,
        bounds: location.boundingbox
      });
    },

    historyBuilder(item, historyArray) {
      let parent = this.entityParentByChild(item)[0];

      if (parent == undefined) {
        return;
      } else {
        let historyItem = {
          item: parent,
          url: "/" + this.currentRoot + "/" + "entity/" + parent.docId
        };
        historyArray.unshift(historyItem);
        this.historyBuilder(parent, historyArray);
      }
    },
    convertFilterToText(filter) {
      let displayName = "";
      if (filter.field == "name") {
        displayName = "Name";
      } else {
        displayName =
          this.entityTypeByType(filter.type)[0] == undefined
            ? ""
            : this.entityTypeByType(filter.type)[0].fields[filter.field]
                .displayName;
      }

      let comparison = "";
      let value = filter.value;

      if (filter.comparison == "contains") {
        comparison = "=";
        value = "*" + value + "*";
      } else {
        comparison = filter.comparison;
      }

      //debugger

      return displayName + " " + comparison + " " + value;
    },
    toggleFilter(show) {
      this.showFilter = show;

      this.$nextTick(() => {
        let selectElems = document.querySelectorAll("select");
        let selectInstances = M.FormSelect.init(selectElems, {});
      });
    },
    leaveFeedback(){
        //redirect to feedback page
    },
    clearFilter() {
      this.$store.commit("setFilter", []);
    },
    clearFilterItem(filter) {
      let currentFilters = this.$store.getters.getFilter;

      currentFilters = currentFilters.filter(item => {
        return item != filter;
      });
      this.$store.commit("setFilter", currentFilters);
    },
    updateFilter() {
      let currentFilters = this.$store.getters.getFilter;

      currentFilters.push({
        type: this.filterEntity,
        field: this.filterField,
        comparison: this.filterOperator,
        value: this.filterValue
      });

      this.filterEntity = null;
      this.filterField = null;
      this.filterOperator = "=";
      this.filterValue = null;

      this.$store.commit("setFilter", currentFilters);

      this.toggleFilter(false);
    },
    edit() {
      this.$router.push(
        "/" +
          this.currentRoot +
          "/entity/" +
          this.currentEntity.docId +
          "/edit/" +
          this.currentEntity.type
      );
    },
    add() {
      this.$router.push(
        "/" +
          this.currentRoot +
          "/entity/" +
          this.currentEntity.docId +
          "/addentity/" +
          this.selectedAddType
      );
    },
    notEmptyObject(someObject) {
      return Object.keys(someObject).length;
    },
      currentEntityType(type) {

      debugger
      if(type)
      {
         return this.entityTypesByParentType(type);
      }
      else
      {
        return undefined;
      }
    
     
    }
  },
  computed: {
    ...mapState(["entities", "entityTypes", "feedback", "filters", "activeRecordSate"]),
    ...mapGetters([
      "allEntities",
      "allEntityTypes",
      "entityByDocId",
      "entityTypesByParentType",
      "entityByParent",
      "entityTypeByType",
      "entityByType",
      "entityParentByChild",
      "entitiesInView",
      "geoJsonElements",
      "feedbackCount",
      "user",
    ]),
 
    selectButtonText() {

      let obj = this.currentEntityTypesEdit.find(item => item.type == this.selectedAddType)
      return obj.displayName;

    },
    currentRoot: function() {
      if (this.$route.params.root) {
        return this.$route.params.root;
      } else {
        ("/map/entity/gnzMtAZnfEwWjsUW3IHX/addentity/feedback");
      }
    },
    currentEntity: function() {
      if (this.$route.params.id) {
        return this.entityByDocId(this.$route.params.id)[0];
      } else {
        if (this.$route.params.root) {
          return this.entityByType(this.$route.params.root)[0];
        } else {
          return this.entityByType("map")[0];
        }
      }
    },
    // inlineFilter() {

    //   debugger
    //   return this.inlineFilterData;

    // },

    childEntities() {
   
       let foundEntities = [];
      if (this.$route.params.id) {
        foundEntities = this.entityByParent(this.$route.params.id);
      } else {
        if (this.currentEntity) {
          let children = this.entityByParent(this.currentEntity.docId);
          foundEntities = children;
        }
      }
      if (this.syncNodes) {
        return foundEntities.filter(item => {
          return _.orderBy(
            this.entitiesInView.indexOf(item.docId) != -1,
            "name"
          );
        });
      } else {
        foundEntities = foundEntities.filter(item => {
          return item.show == true;
        });

        if(this.inlineFilterData && this.inlineFilterData != '')
        {

             var currentFilter = this.inlineFilterData;
             foundEntities = foundEntities.filter((item) => {
               let found = item.name.toLowerCase().indexOf(currentFilter.toLowerCase());
               
               return found != -1})

        }
       
        //let children = this.entityByParent(this.currentEntity.docId);

        //debugger

        let groupedEntities = [];
    
        for (let entityType of this.currentEntityTypes) {
          let states = entityType.states;
          let foundStates = []; 

          if (states == undefined) {
            states = [];
            states.push({ name: "default", role: "*" });
          }

          for (let state of states) {
            let currentChildren = [];
            if (state.children) {
              state.children = [];
            }
            currentChildren = foundEntities.filter(child => {
              if (child.state) {
                return (
                  child.type == entityType.type && child.state == state.name
                );
              } else {
                if (state.name == "default") {
                  return child.type == entityType.type;
                } else {
                  return false;
                }
              }
            });

            if (currentChildren.length != 0) {
              if (entityType.orderBy) {

                state["children"] = _.orderBy(
                  currentChildren,
                  entityType.orderBy
                );
              } else {
                state["children"] = _.orderBy(currentChildren, "name");
              }
              foundStates.push(state);
            }
          }

          groupedEntities.push({
            type: entityType,
            states: foundStates
          });
        }

        return groupedEntities;
      }
    },
    filterEntities: function() {
      // let filterItems = []
      // for(let entityType of this.allEntityTypes)
      // {

      // }
      // debugger

      return _.orderBy(
        this.allEntityTypes.filter(item => {
          if (item.showFilter != undefined) {
            if (item.showFilter) {
              return item.type;
            }
          } else {
            return item.type;
          }

          //return item.type != "map" && item.type != "users";
        }),
        "displayName"
      );
    },
    filterFields: function() {
      let fields = [];

      if (this.filterEntity) {
        let selectedEntity = this.filterEntities.find(item => {
          return item.type == this.filterEntity;
        });
        for (let field in selectedEntity.fields) {
          fields.push({
            displayName: selectedEntity.fields[field].displayName,
            value: field,
            type: selectedEntity.fields[field].type
          });
        }
      }

      fields.push({
        displayName: "Name",
        value: "name",
        type: "Name"
      });

      return _.orderBy(fields, "displayName");
    },
    currentEntityTypes: function() {
      if (this.currentEntity == undefined) {
        return [];
      }
    
      return this.entityTypesByParentType(this.currentEntity.type);
    },
    currentEntityTypesEdit: function() {
      let editableTypes = this.currentEntityTypes.filter(item => {
        if (this.user) {
          if (item.requiredRole) {
            return (
              item.requiredRole.indexOf(this.user.role) != -1  || item.requiredRole == "*"
            );
          } else {
            return true;
          }
        } else {
          if (item.requiredRole) {
            return item.requiredRole == "*";
          } else {
            return true;
          }
        }
      });

      if (editableTypes.length != 0) {
        this.selectedAddType = editableTypes[0].type;
      } else {
        this.selectedAddType = "";
      }

      return editableTypes;
    },

    // childEntities: function() {
     
    // },
    formFields: function() {
      if (this.currentEntity) {
        let currentType = this.entityTypeByType(this.currentEntity.type);

        if (currentType.length != 0) {
          let typeFound = currentType[0];
          let fieldBulider = [];
          for (let field in typeFound.fields) {
            if (typeFound.fields[field].visible) {
              fieldBulider.push({
                name: field,
                displayName: typeFound.fields[field].displayName,
                type: typeFound.fields[field].type,
                value: this.currentEntity.properties[field],
                order: typeFound.fields[field].order,
                
              });
            }
          }
            // fieldBulider.push({
            //     userLink:this.currentEntity.createdBy
            //   });
          return _.orderBy(fieldBulider, "order");
        }
      }
    },
    history: function() {
      let historyArray = [];

      if (this.currentEntity) {
        let historyItem = {
          item: this.currentEntity,
          url:
            "/" + this.currentRoot + "/" + "entity/" + this.currentEntity.docId
        };
        historyArray.push(historyItem);
        this.historyBuilder(this.currentEntity, historyArray);
      }

      return historyArray;
    },
    videoEntity() {
      if (
        this.currentEntity !== undefined &&
        this.currentEntity.hasOwnProperty("properties")
      ) {
        if (this.currentEntity.properties.hasOwnProperty("video")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  },
  watch: {
    filterFields() {
      this.$nextTick(() => {
        let selectElems = document.querySelectorAll("select");
        let selectInstances = M.FormSelect.init(selectElems, {});
      });
    },
    $route(to, from) {

      // Reconfig Accords
      this.$nextTick(() => {
        let elems = document.querySelectorAll(".collapsible");
        let instances = M.Collapsible.init(elems, {});
      });
      
      // Reset Filter Params
      this.inlineFilterData = '';

      if (this.currentEntity) {
        console.log(to);

        this.entity = this.currentEntity.docId;

        this.closePdfViewer();
        this.closeImgViewer();

        //Scroll to top of overviewbody between routes
        let scrollBody = document.getElementsByClassName("overview-body")[0];
        if (scrollBody) {
          scrollBody.scrollTop = 0;
        }
      }

      this.$nextTick(() => {
        let selectElems = document.querySelectorAll("select");
        let selectInstances = M.FormSelect.init(selectElems, {});
      });
    },
    feedbackCount(to, from) {
      if (to > from) {
        this.wiggleFeedback = true;

        setTimeout(() => {
          this.wiggleFeedback = false;
        }, 1000);
      }
    }
  },
  beforeDestroy() {
    this.$root.$off("updateFeedbackVis", this.updateFeedbackVisibility);

    this.pdfShow = false;
    this.pdfViewerUrl = "";
    this.numPages = undefined;
    this.pdfLoadedRatio = 0;
    this.imgShow = false;
    this.imgViewerUrl = "";
    this.imgViewerName = "";
    this.imgZoom = 1;
  }
};
</script>