<template>
    <div class="render render-video">
        <div class="video-cont" v-if="streamlink !== ''">
            <video
                id="video"
                ref="video"
                controls
                autoplay="true"
                muted
                playsinline
                :src="streamlink"
            ></video>
        </div>
    </div>
</template>
<script>
// require styles


export default {
    name: "viewerVideo",
    props: {
        video: String
    },
    data() {
        return {
            streamlink: this.video
        }
    },
    computed: {
        player() {
            return this.$refs.video;
        }
    },
    mounted() {
        this.$refs.video.play();
        this.$store.commit("addNavClasses", 'video-inview');
    },
    destroyed() {
        console.log('video destroyed');
        this.$store.commit("removeNavClasses", 'video-inview');
    },
    watch: { 
      	video: function(newVal, oldVal) {
              console.log(newVal)
              this.$refs.video.pause();
              this.streamlink = newVal;
              this.$refs.video.play();
        }
    },
    methods: {
       
    }
}
</script>