<template>
    <section class="site-sticky" :class="className" :id="id" :style="{height: fullScreen }">
        <div class="container">
            <div class="row">
                <div class="col s12 m12 l12">
                    <ul>
                        <li><a @click="isOpen = false" href="#vision">The Vision</a></li>
                        <li><a @click="isOpen = false" href="#goals">Our Goals</a></li>
                        <!-- <li><a href="#">Project Stages</a></li> -->
                        <li><a @click="isOpen = false" href="#news">Latest News</a></li>
                        <li><a @click="isOpen = false" href="#contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mobile-menu">
            <img class="mobile-logo" src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg" alt="">
            <button @click.prevent="isOpen = !isOpen">
                <i class="fas fa-bars" v-if="!isOpen"></i>
                <i class="fas fa-times" v-else></i>
            </button>
        </div>
    </section>
</template>
<script>
export default {
    name: 'siteNav',
    props: {
        id: String,
        className: String
    },
    computed: {
        fullScreen() {
            if(this.isOpen){
                return '100%'
            } else {
                return '80px'
            }
        }
    },
    data() {
        return {
            isOpen: false
        }
    }
}
</script>