<template>
    <div class="modal-overlay" :id=id @click="$emit('close-modal')">
        <div class="modal-mask"  @click.stop>
            <div class="modal-wrapper">
                <div class="modal-container image-container">
                    <button class="modal-default-button btn-close"  @click="closeModal()">
                            X
                    </button>
                  
                    <div class="modal-body">
                      <div class="image-modal">  
                        <img :src="imageSrc"/>
                      </div>
                       
                    </div>
                </div>
            </div>
        </div> 
     
   
    </div>
  </template>
  
  <script>
    export default {
        name: 'Modal',
  props: {
    imageSrc: String,
    id:String
  },
  // methods:{
  //   closeModal(){

  //   }
  // }
  }
  </script>
  <style lang="scss">
  .modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  width: 100% !important;
}


    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }
    
    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    
    .modal-container {
      width: 300px;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
    }
    
    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }
    
    .modal-body {
      margin: 20px 0;
      width: 100% !important;
      height: 80vh;
    }
    
    .modal-default-button {
      float: right;
    }
    
    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */
    
    .modal-enter {
      opacity: 0;
    }
    
    .modal-leave-active {
      opacity: 0;
    }
    
    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    
    </style>