<template>
    <div class="branding">
        <div class="branding-row">
            <div class="branding-col branding-col-small">
                <div 
                    v-if="logo" 
                    class="branding-logo"
                    :style="{ width: logoWidth }"
                >
                    <a href="#" @click.prevent="goHome()">
                        <img 
                            :src="logo.img" 
                            :alt="logo.alt" 
                        />
                    </a>
                </div>
                <div v-if="name" class="branding-name">
                    <h1>{{name}}</h1>
                </div>
            </div>
            <div class="branding branding-col branding-col-fluid">
                <slot></slot>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "Branding",
    props: {
        logo: {
            img: String,
            alt: String,
            width: Number
        },
        name: String
    },
    computed: {
        logoWidth() {
            return this.logo.width + 'px';
        }
    },
    methods: {
        goHome() {
            this.$router.push("/map");
        }
    }
};
</script>
