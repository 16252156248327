<template>
    <div id="map-key" :class="{hideKey: hideKey}" v-if="mayKeyEnabled">
      <div class="map-key--panel">
        <img :src="mapKeyImage" alt="Key">
        <button @click.prevent="toggleKey()">
            <div class="switch">
                <span class="slider round"></span>
            </div>
            <span>Toggle Key</span>
        </button>
      </div>
    </div>
</template>

<script>
import Settings from "@/mixins/AppSettings.js";
export default {
    name: "MapKey",
    data() {
        return {
            mayKeyEnabled: Settings.showMapKey,
            hideKey: true,
            mapKeyImage: Settings.imageMapKey
        }
    },
    methods: {
        toggleKey() {
            this.hideKey = !this.hideKey;
        }
    }
};
</script>
