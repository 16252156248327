<template>
    <section class="site-footer">
        <div class="container">
            <div class="row">
                <div class="col s12 m6">
                    <div class="links-logo">
                        <div>
                            <img src="@/assets/images/GCC/gcc-logo-sm.png" alt="www.gloucestershire.gov.uk" />
                        </div>
                        <div>
                            <ul>
                                <li><a href="https://www.gloucestershire.gov.uk/council-and-democracy/data-protection/privacy-notices/" target="_blank">Privacy notices</a></li>
                                <li><a href="https://www.gloucestershire.gov.uk/gloucestershire-county-council-news/" target="_blank">Latest news</a></li>
                                <li><a href="https://www.gov.uk/" target="_blank">Gov.uk</a></li>
                                <li><a href="https://www.gloucestershire.gov.uk/sitemap/" target="_blank">Sitemap</a></li>
                                <li><a href="https://www.gloucestershire.gov.uk/accessibility/" target="_blank">Accessibility</a></li>
                                <li><a href="tel:+44-1452-425000" target="_self">Call us on 01452 425000</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col s12 m6">
                    <div class="social">
                        <div class="pcg-footer-contact-table">
                            <h4 class="pcg-footer-contact-link">
                                <a href="https://www.gloucestershire.gov.uk/contact-us/">Contact us</a>
                            </h4>
                        <div class="icons">
                            <a href="https://www.facebook.com/GloucestershireCountyCouncil/" target="_blank" title="Follow us on Facebook" >
                                <img src="@/assets/images/template/footer/fb.png" alt="">
                            </a>
                            <a href="https://twitter.com/GlosCC" target="_blank" title="Follow us on Twitter">
                                <img src="@/assets/images/template/footer/twitter.png" alt="">
                            </a>
                            <a href="https://www.youtube.com/gloscountycouncil" target="_blank" title="Follow us on Youtube">
                               <img src="@/assets/images/template/footer/yt.png" alt="">
                            </a>
                            <a href="https://www.gloucestershire.gov.uk/gloucestershire-county-council-news/rss/" target="_blank" title="News RSS">
                                <img src="@/assets/images/template/footer/rss.png" alt="">
                            </a>
                        </div>
                        <div class="pcg-footer-copyright">© 2020 Copyright Gloucestershire County Council</div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "site-footer",
};
</script>
