<template>
  <main>
    <!-- <HomesEnglandHeader /> -->
    <component v-bind:is="templateName"></component>
  </main>
</template>
<script>
import timeline from "../components/customPages/timeline";
import aboutPage from "../components/customPages/about";
import homePage from "../components/customPages/home";
import faqPage from "../components/customPages/faq";
import furtherInfoPage from "../components/customPages/furtherInfoPage";
import schemesPage from "../components/customPages/schemes";
import feedback from "../components/customPages/feedback";
import latestNews from "../components/customPages/latestNews";
import HomesEnglandHeader from "@/components/HomesEngland/Header";
import Header from "@/components/Header";

export default {
  name: "TemplateLoader",
  components: {
    timeline,
    // HomesEnglandHeader,
    aboutPage,
    homePage,
    // faqPage,
    // schemesPage,
    // feedback,
    // furtherInfoPage,
     latestNews
  },
  data() {
    return {};
  },
  computed: {
    templateName() {
      return this.$route.params.templateName;
    },
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
