<template>
  <section id="view-panel">
    <!-- <div id="map" :style="mapStyleOffset">
    </div> -->
    <!-- <SatView /> -->
    <!-- <img src="../assets/images/mapImages/Pollokshaws-Concept_1-200.jpg" style="width:100%"/> -->
  </section>
</template>

<script>
/* eslint-disable */

import Settings from "@/mixins/AppSettings.js";
const mapboxgl = require("mapbox-gl");
const MapboxDraw = require("@smintman/mapbox-gl-draw/dist/mapbox-gl-draw");

import "@smintman/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { mapState, mapActions, mapGetters } from "vuex";
import * as turf from "@turf/turf";
import { debug } from "util";
import firebase from "firebase";
import _ from "lodash";
import SatView from "@/components/SateliteViewToggle";
// Temporary Imports

export default {
  name: "MapBox",
  props: ["consultationID"],
  components: { SatView },
  data() {
    return {
      map: null,
      searchTitle: "Locate Your Postcode",
      searchShow: true,
      errors: {},
      markers: [],
      // currentNewMarker: null,
      siteFilter: null,
      allPoints: null,
      isIE11: false,
      mapInteractions: [],
      parent: this.$route.params.id,
      includeParent: true,
      customMakers: [],
      customPatterns: [],
      syncNodes: false,
      onlyShowChildren: true,
      drawControl: undefined,
      layers: [],
      // largeLayers: [],
      // largeFeatures: [],
      // availableLargeFeatures: [],
    };
  },
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters([
      "allEntities",
      "entityByDocId",
      "entityByType",
      "entityTypesByParentType",
      "entityByParent",
      "entityTypeByType",
      "postcodeSearch",
      "geoJsonElements",
      "largeGeoJsonElements",
      "sateliteView"
    ]),
    mapStyleOffset: function () {
      //NOTE only N25
      // if (
      //   this.$route.params.type == "feedback" &&
      //   this.isMobileDevice() == false
      // ) {
      //   return "margin-left: 25vw;";
      // } else {
      //   return "";
      // }
    },
    currentEntity: function () {
      return this.entityByDocId(this.$route.params.id)[0];
    },
    // filteredLargeGeoJsonElements: function () {
    //   // debugger;

    //   let filteredLargeGeoJsonElements = [];

    //   let currentEntity = {};

    //   if (this.$route.params.id) {
    //     currentEntity = this.entityByDocId(this.$route.params.id)[0];
    //   } else {
    //     currentEntity = this.entityByType(this.$route.params.root)[0];
    //   }
    //   if (currentEntity == undefined) {
    //     return filteredLargeGeoJsonElements;
    //   }

    //   let children = this.getChildrenForEntity(currentEntity);

    //   return (filteredLargeGeoJsonElements = this.largeGeoJsonElements.filter(
    //     (item) => {
    //       return children.indexOf(item.docId) != -1;
    //     }
    //   ));

    //   return filteredLargeGeoJsonElements;
    // },

    filteredGeoJsonElements: function () {
      let filteredGeoJsonElements = [];

      let currentEntity = {};

      if (this.$route.params.id) {
        currentEntity = this.entityByDocId(this.$route.params.id)[0];
      } else {
        currentEntity = this.entityByType(this.$route.params.root)[0];
      }

      if (currentEntity == undefined) {
        return filteredGeoJsonElements;
      }

      if (
        (currentEntity.properties.showChildren == undefined
          ? true
          : currentEntity.properties.showChildren) == false
      ) {
        return (filteredGeoJsonElements = this.geoJsonElements.filter(
          (item) => {
            return item.properties.docId == currentEntity.docId;
          }
        ));
      }

      let children = this.getChildrenForEntity(currentEntity);

      return (filteredGeoJsonElements = this.geoJsonElements.filter((item) => {
        if (item.properties["pinpoint_showOnlyOn"]) {
          if (item.properties["pinpoint_showOnlyOn"].indexOf(currentEntity.docId) != -1) {
       
            return (
              children.indexOf(item.properties.docId) != -1 &&
              item.properties.show == true &&
              item.properties.showOnMap == true
            );
          }
          else
          {
            return false;
          }
        }

        return (
          children.indexOf(item.properties.docId) != -1 &&
          item.properties.show == true &&
          item.properties.showOnMap == true
        );
      }));
    },
  },
  created() {
    this.$root.$on("refreshMap", this.refreshMap);
  },
  beforeDestroy() {
    this.$root.$off("refreshMap", this.refreshMap);
  },
  async mounted() {
    this.configMap();

    this.refreshMap();

    this.createclusteredView();

    this.map.on("style.load", () => {
      console.log("map loaded");

      //debugger
      //this.loadLargeGeoElements();


      var routeThis = this;
    });

    this.map.on("sourcedata", () => {
      //console.log("source loaded!");
      // for (let largeLayer of this.largeLayers) {
      //   if (
      //     this.map.getSource(largeLayer) &&
      //     this.map.isSourceLoaded(largeLayer)
      //   ) {
      //     let features = this.map.querySourceFeatures(largeLayer);
      //     if (features.length > 0 && features != undefined) {
      //       if (
      //         this.availableLargeFeatures.find((item) => {
      //           return item.featureId == largeLayer;
      //         }) == undefined
      //       ) {
      //         this.availableLargeFeatures.push({
      //           featureId: largeLayer,
      //           features: features,
      //         });
      //       }
      //     }
      //   }
      // }
    });

    var zoomThis = this;
    this.map.on("zoomend", (zoomObj) => {
      let zoom = zoomObj.target.getZoom();

      console.log("current zoom", zoom);
      zoomObj.target;
    });
  },
  updated() {
    //console.log("updated");
  },

  methods: {
    ...mapActions(["updateCoordinates"]),
    toggleSatView(showSatView) 
    {
      if(showSatView)
      {
         this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible');
      }
      else
      {
         this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none');
      }
     
    },
    isMobileDevice() {
      if (window.innerWidth < 1366) {
        return true;
      } else {
        return false;
      }
    },
    async refreshMap(mapInteractions, updateMap, drawOptions, editGeoJson) {
      //console.log("refreshing map");

      if (this.entities.length == 0) {
        await this.$store.dispatch("populateEntities");
      }

      // if (this.entityTypes.length == 0) {
      //   await this.$store.dispatch("populateEntityTypes");
      // }

      // if (this.map) {
      //   if (this.currentNewMarker) {
      //     this.currentNewMarker.remove();
      //   }

      if (updateMap == true) {
        let editMode = false;
        if (mapInteractions.length != 0) {
          editMode = true;
        }

        this.mapInteractions = mapInteractions;

        //   //   this.pinid = this.$route.params.pinid;

        //   //   this.map.off("click", this.addNewMarker);
        //   //   for (let action of this.mapInteractions) {
        //   //     if ((action.type = "pin")) {
        //   //       this.map.on("click", this.addNewMarker);
        //   //     }
        //   //   }

        this.createclusteredView(editMode, drawOptions,editGeoJson);
      }

      //   //this.displayPoints();
      // }
    },
    configMap() {
      // Add Access Token to Mapbox

      //console.log("calling config map");

      // Configure Basic Map
      if (!this.map) {
        //console.log("configuring map");

        mapboxgl.accessToken = Settings.mapConfig.accessToken;
        this.map = new mapboxgl.Map({
          container: "map",
          style: Settings.mapConfig.templatename,
          center: Settings.mapConfig.startLocation,
          zoom: Settings.mapConfig.startZoom,
          pitch: Settings.mapConfig.startPitch,
          antialias: true,
        });
      }
    },
    getChildrenForEntity(currentEntity) {
      let newArray = [];
      newArray.push(currentEntity.docId);

      let children = this.entities.filter((item) => {
        return item.parent == currentEntity.docId;
      });

      if (children.length > 0) {
        for (let child of children) {
          this.parentChildResolve(newArray, child);
        }
      }

      return newArray;

      //console.log("completed array", newArray);
    },
    rotateCamera(timestamp) {
      // clamp the rotation between 0 -360 degrees
      // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
      this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
      // Request the next frame of the animation.
      requestAnimationFrame(this.rotateCamera);
    },
    parentChildResolve(newArray, node) {
      let children = this.entities.filter((item) => {
        return item.parent == node.docId;
      });
      newArray.push(node.docId);

      if (children.length > 0) {
        for (let child of children) {
          this.parentChildResolve(newArray, child);
        }
      } else {
        return newArray;
      }
    },
    resolveSiteName(slug) {
      let filteredSite = this.siteFilter.filter((site) => {
        return site.slug == slug;
      });
      return filteredSite[0].name;
    },
    // addNewMarker(e) {
    //   if (this.currentNewMarker) {
    //     this.currentNewMarker.remove();
    //   }

    //   let coordinatesArray = [e.lngLat.lng, e.lngLat.lat];
    //   this.currentNewMarker = new mapboxgl.Marker({
    //     draggable: true,
    //     color: "red"
    //   })
    //     .setLngLat(coordinatesArray)

    //     .addTo(this.map);

    //   this.currentNewMarker.on("dragend", this.onDragEndNewMarker);

    //   let lngLat = this.currentNewMarker.getLngLat();

    //   for (let action of this.mapInteractions) {
    //     this.$root.$emit(action.eventSource, action.eventSource, lngLat);
    //   }
    // },
    obsKeysToString(o, k, sep) {
      var newArray = [];

      for (let item of o) {
        newArray.push(item[k]);
      }
      return newArray.join(sep);
    },
    // General Methods
    togglePostcodeSearch() {
      this.searchShow = !this.searchShow;
    },
    clearMarkers() {
      for (let marker of this.markers) {
        marker.remove();
      }
    },
    viewSelectedLayer(docId) {
      this.$router.push("/" + this.$route.params.root + "/entity/" + docId);
    },
    gotoSite(e) {
      const self = this;
      var features = this.map.queryRenderedFeatures(e.point);

      //console.log("goToSite() Features:");
      //console.log(features);

      // Gets first list of feature
      if (features.length != 0) {
        let docId = features[0].properties.docId;

        let coordinates = features[0].geometry.coordinates.slice();
        let name = features[0].properties.name;
        let type = features[0].properties.type;
        let docid = features[0].properties.docId;

        let entityType = this.entityTypeByType(type)[0];

        if (coordinates.length > 0) {
          coordinates = e.lngLat;
        }
        this.map.flyTo({ center: coordinates });

        if (docId) {
          // Build HTML for popup
          let html = "";

          html += `<div class="popup-header"><h3>` + name + `</h3></div>`;
          html += `<div class="popup-body">`;

          for (let property in features[0].properties) {
          
            if (property.indexOf("pinpoint_") != -1) {
              
              let visible = entityType.fields[property.replace('pinpoint_','')].popupVisible;
            
              if (visible) {
                html +=
                  `<div class="tr">
                    <div>` +
                  features[0].properties[property] +
                  `</div>
                  </div>`;
              }
            }
          }

          if (Settings.showFeedbackButton) {
            html += `
						<div class="btn-group">
						  <button class="btn" id="popupFeedbackBtn">Leave Feedback</button>
						  <button class="btn" id="popupViewSiteBtn">Goto</button>
						</div>
					</div>
					`;
          } else {
            html += `
						<div class="btn-group">
				
						  <button class="btn" id="popupViewSiteBtn">Goto</button>
						</div>
					</div>
					`;
          }

          // Bind Popup
          const popup = new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(this.map);

          // Add click using vanilla js.
          document
            .getElementById("popupViewSiteBtn")
            .addEventListener("click", function () {
              self.viewSelectedLayer(docId);
              popup.remove();
            });

          if(Settings.popupFeedbackBtn)
          {
            document
            .getElementById("popupFeedbackBtn")
            .addEventListener("click", function () {
              self.$root.$emit("updateFeedbackVis", true, false);
              popup.remove();
            });
        }
          }
       
      }
    },
    gotoSiteLite(e) {
      const self = this;
      var features = self.map.queryRenderedFeatures(e.point);

      // Gets first list of feature
      if (features.length != 0) {
        let docId = features[0].properties.docId;

        let coordinates = features[0].geometry.coordinates.slice();
        let name = features[0].properties.name;
        let type = features[0].properties.type;

        let entityType = self.entityTypeByType(type)[0];

        if (coordinates.length > 0) {
          coordinates = e.lngLat;
        }
        if (name) {
          self.map.flyTo({ center: coordinates });
          self.viewSelectedLayer(docId);
        }
      }
    },
    updateFeaturesInView() {
      if (this.syncNodes) {
        let bounds = this.map.getBounds();
        let features = this.map.queryRenderedFeatures(bounds).filter((item) => {
          return item.source == "entities";
        });

        let docIds = [];

        for (let feature of features) {
          docIds.push(feature.properties.docId);
        }

        //console.log("viewable features", docIds);

        this.$store.commit("setEntitiesInView", docIds);
      }
    },
    // async loadLargeGeoElements() {
    //   let largeElements = this.filteredLargeGeoJsonElements;

    //   console.log(largeElements);
    //   // debugger

    //   for (let layer of this.largeLayers) {
    //     var mapLayer = this.map.getLayer(layer);

    //     if (mapLayer != undefined) {
    //       console.log("removing layer", layer);
    //       this.map.removeLayer(layer);
    //       this.largeLayers = this.largeLayers.filter((item) => {
    //         return item != layer;
    //       });
    //     }
    //   }

    //   for (let largeElement of largeElements) {
    //     let dataObj = JSON.parse(largeElement.data);

    //     var mapSource = this.map.getSource(largeElement.docId);
    //     if (typeof mapSource != "undefined") {
    //     } else {
    //       this.map.addSource(largeElement.docId, {
    //         type: "geojson",
    //         data: dataObj.url,
    //       });
    //     }

    //     if (largeElement) {
    //       let layer = {
    //         id: largeElement.docId,
    //         type: dataObj.type,
    //         source: largeElement.docId,
    //         // "source-layer": largeElement.docId,
    //         layout: {},
    //         interactive: true,
    //         paint: dataObj.paint,
    //       };

    //       if (dataObj.maxzoom) {
    //         layer.maxzoom = largeElement.data.maxzoom;
    //       }

    //       if (dataObj.minzoom) {
    //         layer.minzoom = largeElement.data.minzoom;
    //       }

    //       var mapLayer = this.map.getLayer(largeElement.docId);

    //       if (mapLayer == undefined) {
    //         let currentLayer = this.map.addLayer(layer);
    //         console.log("Adding layer", largeElement.docId);
    //         this.largeLayers.push(largeElement.docId);
    //       }

    //       // this.map.on("render", () => {
    //       //   var features = this.map.querySourceFeatures(largeElement.docId);
    //       //   debugger;
    //       // });
    //     }
    //   }
    // },

    //console.log("calling create clusteredview");
    //debugger
    updateEdit(e) {
      let data = this.drawControl.getAll();

      for (let action of this.mapInteractions) {
        this.$root.$emit(action.eventSource, action.eventSource, data);
      }
    },
    toggleLayers(visible) {
      for (let layer of this.layers) {
        if (visible) {
          this.map.setLayoutProperty(layer, "visibility", "visible");
        } else {
          this.map.setLayoutProperty(layer, "visibility", "none");
        }
      }
    },
    async createclusteredView(editMode, drawOptions,editGeoJson) {
      //debugger

      let currentDrawData = undefined;

      if (editMode == true) {
        this.toggleLayers(false);
        this.drawControl = new MapboxDraw(drawOptions);
        this.map.addControl(this.drawControl, "top-right");

        if(drawOptions.defaultControl)
        {
           this.drawControl.changeMode(drawOptions.defaultControl);
        }
       
        this.map.off("draw.update", this.updateEdit);
        this.map.on("draw.update", this.updateEdit);
        this.map.off("draw.create", this.updateEdit);
        this.map.on("draw.create", this.updateEdit);
        this.map.off("draw.delete", this.updateEdit);
        this.map.on("draw.delete", this.updateEdit);
      } else {
        this.toggleLayers(true);

        if (this.drawControl) {
          currentDrawData = this.drawControl.getAll();

          this.map.removeControl(this.drawControl);
          this.drawControl = undefined;
        }
      }

      // this.loadLargeGeoElements();

      var geojson = {};

      if (this.filteredGeoJsonElements.length > -1) {
        let self = this;

        let geoJsonToRender = {};

        geojson = {
          type: "FeatureCollection",
          features: this.filteredGeoJsonElements,
        };

        // filteredLargeGeoJsonElements

        if (this.drawControl) {
          let geoJsonToEdit = [];
          if (this.$route.params.operation == "edit") {
            geoJsonToEdit = this.filteredGeoJsonElements.filter((item) => {
              return item.properties.docId == this.$route.params.id;
            });
          }

          geojson = {
            type: "FeatureCollection",
            features: geoJsonToEdit,
          };

          this.drawControl.add(geojson);
        } else {
          let customPins = geojson.features.filter((item) => {
            return item.properties.customPin != undefined;
          });

          //Load market images and add to map
          let markers = await firebase.storage().ref().child("pins").listAll();

          for (let marker of markers.items) {
            let markerName = marker.name.split(".")[0];

            let imageExists = this.customMakers.find((item) => {
              return item == markerName;
            });

            if (imageExists == undefined) {
              this.customMakers.push(markerName);
              let downloadURL = await marker.getDownloadURL();

              let image = await this.loadImage(downloadURL);
              this.map.addImage(markerName, image);
            }
          }

          let patterns = await firebase
            .storage()
            .ref()
            .child("patterns")
            .listAll();

          for (let pattern of patterns.items) {
            let patternName = pattern.name.split(".")[0];

            let imageExists = this.customPatterns.find((item) => {
              return item == patternName;
            });

            if (imageExists == undefined) {
              this.customPatterns.push(patternName);
              let downloadURL = await pattern.getDownloadURL();

              let image = await this.loadImage(downloadURL);
              this.map.addImage(patternName, image);
            }
          }

          if (Settings.enableEntityPopup) {
            this.map.off("click", this.gotoSite);
            this.map.on("click", this.gotoSite);
          } else {
            this.map.off("click", this.gotoSiteLite);
            this.map.on("click", this.gotoSiteLite);
          }

          this.map.off("mousemove");
          this.map.on("mousemove", (e) => {
            var features = this.map.queryRenderedFeatures(e.point);

            // let wantedFetured = features.find((item) => {return item.source == "townlands_tad"})

            // if(wantedFetured)
            // {
            //   console.log('town', wantedFetured.properties["NAME_EN"])
            // }

            if (features.length > 0 && features[0].properties.name) {
              let name = features[0].properties.name;
              if (name) {
                if (features.length > 0) {
                  this.map.getCanvas().style.cursor = "pointer";
                }
              }
            } else {
              this.map.getCanvas().style.cursor = "";
            }
          });
        }

        if (currentDrawData) {
          geojson = currentDrawData;
        }

        if(editGeoJson)
        {
          geojson = editGeoJson;
        }

        var mapSource = this.map.getSource("entities");
        if (typeof mapSource != "undefined") {
          this.map.getSource("entities").setData(geojson);
        } else {
          this.map.addSource("entities", {
            type: "geojson",
            data: geojson,
            cluster: false,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 10,
          });
        }

        var mapSource = this.map.getSource("entitiesCluster");
        if (typeof mapSource != "undefined") {
          this.map.getSource("entitiesCluster").setData(geojson);
        } else {
          this.map.addSource("entitiesCluster", {
            type: "geojson",
            data: geojson,
            cluster: false,
            clusterMaxZoom: 14,
            clusterRadius: 20,
            maxzoom: 14,
          });
        }

        this.addLayers();

        this.updateFeaturesInView();
      }

      this.zoomToVisibleFeatures();
    },
    zoomToVisibleFeatures() {
      let zoomToFeatures = {
        type: "FeatureCollection",
        features: [],
      };

      zoomToFeatures.features.push(...this.filteredGeoJsonElements);

      // for (let largeFeature of this.availableLargeFeatures) {
      //   if (
      //     this.filteredLargeGeoJsonElements.find((item) => {
      //       return item.docId == largeFeature.featureId;
      //     }) != undefined
      //   ) {
      //     zoomToFeatures.features.push(...largeFeature.features);
      //   }
      // }

      if (zoomToFeatures.features.length > 0) {
        let bbox = turf.bbox(zoomToFeatures);

        if (this.isMobileDevice() == true) {
          this.map.fitBounds(bbox, { pitch: 0, padding: 80 });
        } else {
          //This code added to try and shift object to be on right hand side of screen to they can
          //by adding a new polygon 0.5 miles away then adding that to existing bounding box to create
          //a new one. Need to update to scale better across bounding boxes.
          let distance = 0.5;
          let bearing = 270;
          let options = { units: "miles" };
          let bboxPoly = turf.bboxPolygon(bbox);

          //Get corners of bounding box
          let from = [bbox[0], bbox[1]];
          let to = [bbox[2], bbox[3]];

          //calculate the distance in miles from corner to corner
          var bboxDistance = turf.distance(from, to, options);

          //Get center of bounding box
          let centroid = turf.centroid(bboxPoly);

          //Get cordinates of the above distance to the west of the center of the bounding box
          var offSet = turf.destination(
            centroid,
            bboxDistance,
            bearing,
            options
          );
          let offSetPolygon = turf.buffer(offSet, 0.025, options);

          //Add the old bounding box to the new one and get the bounds
          bbox = turf.bbox(turf.union(bboxPoly, offSetPolygon));

          this.map.fitBounds(bbox, { pitch: 0, padding: 180 });
        }
      }
    },
    addLayers() {
      // this.map.addSource("sitetest", {
      //   type: "vector",
      //   url: "mapbox://creative-design.50bcby56"
      // });

      // let testLAyer = this.map.addLayer({
      //   id: "creative-design.50bcby56",
      //   type: "fill",
      //   source: "sitetest",
      //   'source-layer': 'studyarea-0l0u2t',
      //   layout: {},
      //   interactive: true,

      //   paint: {
      //     "fill-outline-color": "#9E147A",
      //     "fill-color": "#9E147A",
      //     "fill-opacity": 0.1
      //   }
      // });

      // this.map.addSource("sitetest2", {
      //   type: "vector",
      //   url: "mapbox://creative-design.ben84hgs"
      // });

      // let testLAyer2 = this.map.addLayer({
      //   id: "creative-design.ben84hgs",
      //   type: "line",
      //   source: "sitetest2",
      //   'source-layer': 'townlands_tad',
      //   layout: {},
      //   interactive: true,
      //   paint: {
      //       "line-color": "#fab803",
      //       "line-width": 1
      //     }

      // });

      var mapLayer = this.map.getLayer("polygons");

      if (typeof mapLayer == "undefined") {
        let polygonLayer = this.map.addLayer({
          id: "polygons",
          type: "fill",
          source: "entities",
          layout: {},
          interactive: true,
          filter: ["all", ["==", "$type", "Polygon"], ["!has", "fill-pattern"]],
          paint: {
            "fill-outline-color": [
              "coalesce",
              ["get", "pinpoint_entityColor"],
              "#039be5",
            ],
            "fill-color": [
              "coalesce",
              ["get", "pinpoint_entityColor"],
              "#039be5",
            ],
            "fill-opacity": 0.6,
          },
        });

        this.layers.push("polygons");
      }

      mapLayer = this.map.getLayer("polygons_label");

      if (typeof mapLayer == "undefined") {
        let customMarkerLayer = this.map.addLayer({
          id: "polygons_label",
          type: "symbol",
          source: "entities",

          filter: ["==", "$type", "Polygon"],
          minzoom: 14,
          layout: {
            "text-field": "{name}",

            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        this.layers.push("polygons_label");
      }

      mapLayer = this.map.getLayer("polygonsWithCustomFill");

      if (typeof mapLayer == "undefined") {
        let fillExtrusionLayer = this.map.addLayer({
          id: "polygonsWithCustomFill",
          type: "fill",
          source: "entities",
          layout: {},
          interactive: true,
          filter: ["all", ["==", "$type", "Polygon"], ["has", "fill-pattern"]],
          paint: {
            "fill-outline-color": [
              "coalesce",
              ["get", "pinpoint_entityColor"],
              "#039be5",
            ],
            "fill-color": [
              "coalesce",
              ["get", "pinpoint_entityColor"],
              "#039be5",
            ],
            "fill-opacity": 0.8,
            "fill-pattern": ["get", "fill-pattern"],
          },
        });

        this.layers.push("polygonsWithCustomFill");
      }

      mapLayer = this.map.getLayer("fillExtrusion");

      if (typeof mapLayer == "undefined") {
        let fillExtrusionLayer = this.map.addLayer({
          id: "fillExtrusion",
          type: "fill-extrusion",
          source: "entities",
          layout: {},
          interactive: true,
          filter: ["all", ["==", "$type", "Polygon"], ["has", "height"]],
          paint: {
            "fill-extrusion-color": [
              "coalesce",
              ["get", "pinpoint_entityColor"],
              "#039be5",
            ],
            "fill-extrusion-height": ["get", "height"],
            "fill-extrusion-base": 0,
            "fill-extrusion-opacity": 0.8,
          },
        });

        this.layers.push("fillExtrusion");
      }

      mapLayer = this.map.getLayer("linestrings");
      if (typeof mapLayer == "undefined") {
        let lineStringLayer = this.map.addLayer({
          id: "linestrings",
          type: "line",
          source: "entities",
          layout: {
            "line-cap": "round",
          },
          interactive: true,
          filter: ["all", ["==", "$type", "LineString"], ["!has", "dashed"]],
          paint: {
            "line-color": ["get", "pinpoint_entityColor"],
            "line-width": 1,
          },
        });
        this.layers.push("linestrings");
      }

      mapLayer = this.map.getLayer("linestringsDashed");
      if (typeof mapLayer == "undefined") {
        let linestringsDashedLayer = this.map.addLayer({
          id: "linestringsDashed",
          type: "line",
          source: "entities",
          layout: {
            "line-cap": "round",
          },
          interactive: true,
          filter: ["all", ["==", "$type", "LineString"], ["has", "dashed"]],
          paint: {
            "line-color": ["get", "pinpoint_entityColor"],
            "line-width": 4,
            "line-dasharray": [1, 2],
          },
        });
        this.layers.push("linestringsDashed");
      }

      mapLayer = this.map.getLayer("linestring_label");

      if (typeof mapLayer == "undefined") {
        let lineStringLabelLayer = this.map.addLayer({
          id: "linestring_label",
          type: "symbol",
          source: "entities",

          filter: ["==", "$type", "LineString"],
          minzoom: 7,
          layout: {
            "symbol-placement": "line",
            "text-field": "{name}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
            "text-anchor": "bottom",
            "symbol-spacing": 350,
            "text-keep-upright": true,
          },
          paint: {
            "text-color": "#000000",
            "text-halo-width": 1,
            "text-halo-color": "#FFFFFF",
          },
        });

        this.layers.push("linestring_label");
      }

      mapLayer = this.map.getLayer("customMakerer-points");

      if (typeof mapLayer == "undefined") {
        let customMarkerLayer = this.map.addLayer({
          id: "customMakerer-points",
          type: "symbol",
          source: "entities",
          interactive: true,
          filter: ["all", ["==", "$type", "Point"], ["has", "customMarker"]],
          // minzoom: 14,
          layout: {
           // "text-field": "{name}",
            "icon-image": ["get", "customMarker"],
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-anchor": "top",
          //  "icon-anchor": ["get", "iconAnchor"],
           "icon-anchor": "bottom"
          },
           paint: {
            "text-halo-width": 1,
            "text-halo-color": "#FFFFFF",
          }
        });

        this.layers.push("customMakerer-points");
      }

      mapLayer = this.map.getLayer("defaultMarker-points");

      if (typeof mapLayer == "undefined") {
        let defaultMarkerLayer = this.map.addLayer({
          id: "defaultMarker-points",
          type: "symbol",
          source: "entities",
          interactive: true,
          filter: ["all", ["==", "$type", "Point"], ["!has", "customMarker"]],
         // minzoom: 14,
          layout: {
            "text-field": "{name}",
            "icon-image": "default",
            "icon-size": 1,
            "icon-allow-overlap": true,
            "icon-ignore-placement": true,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-anchor": "top",
            "icon-anchor": "bottom",
          },
          paint: {
            "text-halo-width": 1,
            "text-halo-color": "#FFFFFF",
          }
        });

        this.layers.push("defaultMarker-points");
      }

      mapLayer = this.map.getLayer("clustered-points");

      if (typeof mapLayer == "undefined") {
        let customMarkerLayer = this.map.addLayer({
          id: "clustered-points",
          type: "symbol",
          source: "entitiesCluster",
          interactive: true,
          filter: ["has", "point_count"],
          // minzoom: 14,
          minzoom: 6,
          layout: {
             //"text-field": "{name}",
            "icon-image": "default",
            "icon-size": 1,
            "icon-allow-overlap": false,
            "icon-ignore-placement": true,
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-anchor": "top",
            "icon-anchor": "bottom",
          },
        });

        this.layers.push("clustered-points");
      }

      mapLayer = this.map.getLayer("unclustered-points");

      // if (typeof mapLayer == "undefined") {
      //   let customMarkerLayer = this.map.addLayer({
      //     id: "unclustered-points",
      //     type: "symbol",
      //     source: "entitiesCluster",
      //     interactive: true,
      //     filter: ["!", ["has", "point_count"]],
      //     // minzoom: 14,
      //     maxzoom: 14,
      //     layout: {
      //       //  "text-field": "{name}",
      //       "icon-image": "default",
      //       "icon-size": 1,
      //       "icon-allow-overlap": true,
      //       "icon-ignore-placement": true,
      //       "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
      //       "text-anchor": "top",
      //       "icon-anchor": "bottom",
            
      //     },
      //     paint: {
      //       "text-halo-width": 1,
      //       "text-halo-color": "#FFFFFF",
      //     }
      //   });

      //   this.layers.push("unclustered-points");
      // }

      mapLayer = this.map.getLayer("clustered-circles");

      if (typeof mapLayer == "undefined") {
        let customMarkerLayer = this.map.addLayer({
          id: "clustered-circles",
          type: "circle",
          source: "entitiesCluster",
          maxzoom: 6,
          filter: ["has", "point_count"],
          layout: {
            visibility: "visible",
          },
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#039be5",
              100,
              "#039be5",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        this.layers.push("clustered-circles");
      }

      mapLayer = this.map.getLayer("clustered-count");

      if (typeof mapLayer == "undefined") {
        let customMarkerLayer = this.map.addLayer({
          id: "clustered-count",
          type: "symbol",
          source: "entitiesCluster",

          filter: ["has", "point_count"],
          maxzoom: 6,
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });

        this.layers.push("clustered-count");
      }
    },
    loadImage(imageUrl) {
      return new Promise((resolve, reject) => {
        this.map.loadImage(imageUrl, (error, image) => {
          if (error) {
            reject(error);
          } else {
            resolve(image);
          }
        });
      });
    },
  },
  watch: {
    $route(to, from) {
      this.refreshMap();
      this.createclusteredView();
      // this.loadLargeGeoElements();
      this.zoomToVisibleFeatures();
    },
    availableLargeFeatures(to, from) {
      //Feature data loaded from large geojson
      if (to.length == this.largeLayers.length) {
        this.zoomToVisibleFeatures();
      }
    },
    geoJsonElements(to, from) {
      this.createclusteredView();
      // this.loadLargeGeoElements();
    },
    postcodeSearch(to, from) {
      let bounds = [to.bounds[2], to.bounds[1], to.bounds[3], to.bounds[0]];
      this.map.fitBounds(bounds, { pitch: 0, padding: 120 });
    },
    sateliteView(to, from) {
      console.log('fired');
      this.toggleSatView(to);
    }
  },
};
</script>


