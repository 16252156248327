<template>
  <section class="site-cards" v-if="data">
    <div class="container">
      <div class="row">
        <div class="site-card-col col s12 m6 l4" v-for="(items, index) in data" :key="index">
            <a class="site-card" :href="items.properties.cardFile" target="_blank">
              <div class="site-card-image" :style="{ backgroundImage: `url(${items.properties.cardImage[0].url})`}">
                <p><span>{{ hoverText }}</span></p>
              </div>
              <div class="site-card-title white-text">
                {{ items.properties.cardTitle }}
              </div>
            </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: Array,
    hoverText: String,
  },
};
</script>
