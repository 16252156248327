<template>
    <div class="site-view">
        <section class="site-banner-strip site-banner-strip-green site-banner-strip-newsnav" >
            <div class="container">
                <div class="row row-flex">
                    <div class="col col-flex s12 m8 l8">
                        <div class="site-btn site-btn-blue">
                            <router-link to="/">
                                <span>Go Back</span>
                                To Site
                                <div class="arrow"><i class="fas fa-caret-left"></i></div>
                            </router-link>
                        </div>
                    </div>
                    <div class="col col-flex s12 m4 l4">
                        <img class="logo" src="@/assets/images/pollokshaws/ConnectingLogo.svg" alt="">
                    </div>
                </div>
            </div>
        </section>
        <section class="site-article">
            <div class="container">
                <div class="row row-flex">
                    <div class="col col-flex">
                        <img src="@/assets/images/pollokshaws/banner.jpg" alt="">
                    </div>
                </div>
                <div class="row row-flex">
                    <div class="col col-flex">
                        <div class="content">
                            <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                            <p>Nos paucis ad haec additis finem faciamus aliquando; Tum ille: Tu autem cum ipse tantum librorum habeas, quos hic tandem requiris? Quid, cum fictas fabulas, e quibus utilitas nulla elici potest, cum voluptate legimus? Mihi, inquam, qui te id ipsum rogavi? Non enim solum Torquatus dixit quid sentiret, sed etiam cur. Duo Reges: constructio interrete.</p>
                            <p>Nos paucis ad haec additis finem faciamus aliquando; Tum ille: Tu autem cum ipse tantum librorum habeas, quos hic tandem requiris? Quid, cum fictas fabulas, e quibus utilitas nulla elici potest, cum voluptate legimus? Mihi, inquam, qui te id ipsum rogavi? Non enim solum Torquatus dixit quid sentiret, sed etiam cur. Duo Reges: constructio interrete.</p>
                            <p>Nos paucis ad haec additis finem faciamus aliquando; Tum ille: Tu autem cum ipse tantum librorum habeas, quos hic tandem requiris? Quid, cum fictas fabulas, e quibus utilitas nulla elici potest, cum voluptate legimus? Mihi, inquam, qui te id ipsum rogavi? Non enim solum Torquatus dixit quid sentiret, sed etiam cur. Duo Reges: constructio interrete.</p>
                            <p>Nos paucis ad haec additis finem faciamus aliquando; Tum ille: Tu autem cum ipse tantum librorum habeas, quos hic tandem requiris? Quid, cum fictas fabulas, e quibus utilitas nulla elici potest, cum voluptate legimus? Mihi, inquam, qui te id ipsum rogavi? Non enim solum Torquatus dixit quid sentiret, sed etiam cur. Duo Reges: constructio interrete.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
  name: "latestNews",
  data() {
    return {};
  },
};
</script>
