import Vue from "vue";
export default {
    data () {
        return {
            hideDesc: false,
            hideDescText: 'Read More',
        }
    },
    async mounted() {
        this.hasReadMoreOption();
    },
    watch: {
        $route(to, from) {
            this.hideDesc = false;
            Vue.nextTick(() => {
                this.hasReadMoreOption()
            });
        }
    },
    methods: {
        hasReadMoreOption() {
            let id = document.getElementById('hidden');
            if ( document.getElementById('hidden') ) {
                this.hideDesc = true;
                this.hideDescText = 'Read More';
            } else {
                this.hideDesc = false;
            }
        },
        toggleReadMore() {
            if(this.hideDescText == 'Read More') {
              this.hideDescText = 'Read Less'
              document.getElementById('hidden').classList.add('showHideDesc');
            } else {
              this.hideDescText = 'Read More'
              document.getElementById('hidden').classList.remove('showHideDesc');
            }
        }
    }
}