<template>
  <footer>
    <div class="flex-row">
      <div class="flex-col">
        <p><a href="#">View our Terms &amp; Condtions</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  mounted () {
  
    
  },
  methods: {
   
  }
}
</script>
