import firebase from "firebase";
import Settings from './AppSettings';

const apiCall = {

  async getfirebaseCollectionAll(collectionName) {
    const token = await firebase.auth().currentUser.getIdToken();

    const url = Settings.baseUrl + "collection/" + collectionName + '/';
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.get(url, { headers: header });

    return response.data;

  },
  async addfirebaseCollection(collectionName, data,saveType) {
    let body = { data: data };
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl + "collection/" + collectionName + '?saveType=' + saveType;
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.post(url, body, { headers: header }); 

    return response;

  },

  async bootstrap() {
    let body = {};
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl + "bootstrapAdmin/";
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.post(url, body, { headers: header }); 

    return response;

  },
  async deletefirebaseCollection(collectionName, docId) {
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl  + "collection/" + collectionName + "/" + docId 
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.delete(url, { headers: header });

    return response;

  },
  async updatefirebaseCollection(collectionName, docId, data,saveType) {
    let body = { docId: docId, data: data };
 
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl  + "collection/" + collectionName + '?saveType=' + saveType;;
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.post(url, body, { headers: header });

    return response;

  },
  async getfirebaseCollectionByDocId(collectionName, docId, baseUrl) {
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl  + "collection/" + collectionName + '/' + docId;
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.get(url, { headers: header });

    return response.data;
  },
  async updatefirebaseUser(data) {
    
      const token = await firebase.auth().currentUser.getIdToken();
      const url = Settings.baseUrl  + "users";
      const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
      const response = await axios.post(url, data, { headers: header });
      return response.data

  },
  async deletefirebaseUser(uid) {
   
 
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl  + "users/" + uid ;
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    const response = await axios.delete(url, { headers: header });

    return response.data;

  },
  async submitForm(body) {
 
    const token = await firebase.auth().currentUser.getIdToken();
    const url = Settings.baseUrl  + "submitForm";
    const header = { Authorization: `Bearer ${token}`, "Content-Type": "application/json;charset=UTF-8", Pragma: 'no-cache' };
    
    const response = await axios.post(url, body, { headers: header });

    return response;

  },
  async getFile(url) {

    const response = await axios.get(url);

    return response.data;
  },
  async getCurrentUser() {
    return await firebase.auth().currentUser
  },
  async deleteFromFirebase(fileLink) {
 


    fileLink = fileLink.split('/')[fileLink.split('/').length-1].split("?")[0]

    

    const ref = firebase.storage().ref();
    var desertRef = ref.child(fileLink);

    // Delete the file
    desertRef.delete().then(function () {
      M.toast({
        html: "File deleted in firebase",
        classes: "rounded"
      });
    }).catch(function (error) {
      //alert('Error');
      //console.log(error);
    });

  }
}




export default apiCall;