import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase'
//window.axios = require('axios')
// import VueSlideBar from 'vue-slide-bar'
import axios from 'axios'

import VuejsDialog from 'vuejs-dialog';

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)
import VModal from 'vue-js-modal'
Vue.use(VModal)
// import VueLazyLoad from 'vue-lazyload'

// Vue.use(VueLazyLoad)
//  Vue.use('VueSlideBar', VueSlideBar)
import Api from "./mixins/Apicall";
import Appsettings from "./mixins/AppSettings";
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueI18n from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from '@/localization/en';
import { IRISH_TRANSLATIONS } from '@/localization/ga';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
Vue.config.productionTip = false
let app = '';

Vue.use(VueI18n)

Vue.prototype.$http = axios;

Vue.mixin(Api);

Vue.mixin(Appsettings);

Vue.use(VuejsDialog);

const TRANSLATIONS = {
  en: ENGLISH_TRANSLATIONS,
  ga: IRISH_TRANSLATIONS
}
const i18n = new VueI18n({
  locale: 'en',
  messages: TRANSLATIONS,
})
// Vue.filter('formatDate', function(value) {
//   if (value) {
   
//   }
// });



// var config = {
//   apiKey: "AIzaSyCt0UF_PAGv8tZviayDwrC87MgGbXclaPs",
//   authDomain: "homes-england-pinpoint.firebaseapp.com",
//   databaseURL: "https://homes-england-pinpoint.firebaseio.com",
//   projectId: "homes-england-pinpoint",
//   storageBucket: "homes-england-pinpoint.appspot.com",
//   messagingSenderId: "42239644850",
//   appId: "1:42239644850:web:188fb7b3ac5551b930e5ab",
//   measurementId: "G-HTEM68MPJD"
// };

// var config = {
//   apiKey: "AIzaSyDGAAvNRnK7D14_EsaSKgE0Ci7NpcS8hb0",
//   authDomain: "pinpoint-a34.firebaseapp.com",
//   databaseURL: "https://pinpoint-a34.firebaseio.com",
//   projectId: "pinpoint-a34",
//   storageBucket: "pinpoint-a34.appspot.com",
//   messagingSenderId: "885844676751",
//   appId: "1:885844676751:web:a1e95080f53465a92d60fa",
//   measurementId: "G-9H0Z3B2WRL"
 
// };

const config = {
  apiKey: "AIzaSyBTJJXQ6wAi5pUec-kmIDj3HV_utlNNT6k",
  authDomain: "pinpoint-pollokshaws.firebaseapp.com",
  databaseURL: "https://pinpoint-pollokshaws.firebaseio.com",
  projectId: "pinpoint-pollokshaws",
  storageBucket: "pinpoint-pollokshaws.appspot.com",
  messagingSenderId: "242773812114",
  appId: "1:242773812114:web:2341e19ec3618d4020174b"
};

firebase.initializeApp(config);


firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
    router,
    store,
    firebase,
    i18n,
    render: h => h(App)
  }).$mount('#app')

  window.app = app;
}
});

