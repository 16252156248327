<template>
	<nav class="main-nav">
		<div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="first-half">
                        <div class="site-logos site-logos--menu">
                            <div class="container">
                                <div class="row-flex">
                                    <div class="col-flex col-flex-grow">
                                        <img src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg" alt="">
                                    </div>
                                </div>
                                <div class="row-flex">
                                    <div class="col-flex col-flex-grow">
                                        <p><span>Get Involved</span></br>
                                        Follow Us</br>
                                        On Facebook</br>
                                        And Twitter</p>
                                    </div>
                                </div>
                                 <div class="row-flex">
                                    <div class="col-flex">
                                        <a href="https://www.facebook.com/cpollokshaws" target="_blank">
                                        <img src="@/assets/images/pollokshaws/Facebook2.png" alt="">
                                        </a>
                                    <!-- </div>
                                    <div class="col-flex"> -->
                                        <a href="https://twitter.com/CPollokshaws" target="_blank">
                                        <img src="@/assets/images/pollokshaws/Twitter2.png" alt="">
                                        </a>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="col">
                   
                <div class="second-half">
                     <div class="site-logos site-logos--menu-item">
                        <div class="container">
                            <div class="row-flex">
                                <div class="col-flex">
                                    <router-link to="/">
                                        Home
                                    </router-link>
                                </div>
                            </div>
                            <div class="row-flex">
                                <div class="col-flex col-flex-grow">
                                    <router-link to="/site/aboutPage">
                                        Who we are
                                    </router-link>
                                </div>
                            </div>
                            <div class="row-flex">
                                <div class="col-flex col-flex-grow">
                                    <router-link to="/map/entity/gnzMtAZnfEwWjsUW3IHX/addentity/feedback">
                                        Survey
                                    </router-link>
                                </div>
                            </div>
                            <div class="row-flex">
                                <div class="col-flex col-flex-grow">
                                    <router-link to="/site/timeline">
                                        Project lifecycle
                                    </router-link>
                                </div>
                            </div>
                            
                            <div class="row-flex">
                                <div class="col-flex col-flex-grow">
                                    <a href="https://www.facebook.com/cpollokshaws" target="_blank">Latest news</a>
                                </div>
                            </div>
                            <!-- <div class="row-flex">
                                <div class="col-flex col-flex-grow">
                                    <router-link to="/">
                                        get in touch
                                    </router-link>
                                </div>
                            </div> -->
                        </div>
                     </div>
                   
                    <!-- <div class="row">
                        <div class="col" lg="12">
                            <router-link to="/">
                                Home
                            </router-link>
                        </div>
                    </div> -->
                </div>
            </div>

            </div>
		</div>
	</nav>
</template>
<script>
// @ is an alias to /src
export default {
	name: 'Navigation',
}
</script>
