<template>
    <main>
       <!-- <Header/> -->
      <HomesEnglandHeader/>
      
      <section 
        v-if="!isMobile" 
        id="nav-panel" 
        class="control-panel"
        :class="navClasses"
        >
        <router-view name="a"></router-view>
        <!-- <Footer /> -->
      </section>
      <router-view name="b" v-if="!isMobile"></router-view>


      <MobileLayout v-if="isMobile" ref="mobileComponent" :midValue="midVal">
        <template v-slot:map>
          <router-view name="b"></router-view>
        </template>
        <template v-slot:info>
          <router-view name="a"></router-view>
        </template>
      </MobileLayout>

    </main>
</template>
<script>
// import N25Header from "@/components/N25/Header";
import HomesEnglandHeader from "@/components/HomesEngland/Header";
import Header from "@/components/Header";
import MobileLayout from "@/components/MobileLayout";
import Footer from "@/components/Footer";

export default {
  name: "Cluster",
  // props: ['showadd'],
  components: { Header, MobileLayout, Footer, HomesEnglandHeader },
  data(){
    return {
      isMobile: false,
      midVal: 0,
    }
  },
  computed: {
    navClasses() {
      return this.$store.getters.navClasses;
    },
    videoInView() {
      if(this.navClasses.includes("video-inview")) {
        return true;
      } else {
        return false;
      }
    },
    videoElems() {
      let video  = document.getElementById('video') ? document.getElementById('video') : false;
      let header = document.getElementById('header-panel') ? document.getElementById('header-panel') : false;
      if(video && header) {
        return video;
      } else {
        return false;
      }
    }
  },
  created() {

    const vm = this;
    // Mobile View Code
    vm.isMobileDevice();
    window.onresize = function(){
      vm.isMobileDevice();
    };
    window.onorientationchange = function() { 
      vm.isMobileDevice();
    };
  },
  mounted(){
    this.mobileNavPos();  
  },
  methods: {
    // Mobile View Code
    isMobileDevice() {
      if(window.innerWidth < 1024) {
        this.isMobile = true
      } else {
        this.isMobile = false; 
      }
    },
    mobileNavPos() {
      const vm = this;
      let vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      let offset;
      if(vm.videoInView) {
        offset = 0.45;
        vm.midVal = Math.max(vh * offset);
      } else {
        offset = 0.65;
        vm.midVal = Math.max(vh * offset);
      }

      window.onorientationchange = function() { 
        if(window.orientation === 90 || window.orientation === -90) {
          console.log("the orientation of the device is now " + window.orientation);
          let vh = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
          let offset = 0.65;
          this.midVal = Math.max(vh * offset);
        }
      };
    } 

  },
  watch: {
    // Mobile View Code
    $route(to, from) {
      if(this.$refs.mobileComponent) {
        this.$refs.mobileComponent.mapPointer();
      }
    },
    videoInView(newVal, oldVal) {
      this.mobileNavPos();
    },
  }
};

</script>
