<template>
    <div id="sat-key" v-if="keyEnabled" :class="{hideKey: sateliteView}">
      <div class="sat-key--panel">
        <button @click.prevent="toggleKey()">
            <span>Satellite View</span>
            <div class="switch">
                <span class="slider round"></span>
            </div>
        </button>
      </div>
    </div>
</template>

<script>
import Settings from "@/mixins/AppSettings.js";
import { mapState, mapActions } from 'vuex';
export default {
    name: "SatView",
    data() {
        return {
            keyEnabled: Settings.sateliteView,
        }
    },
    computed: {
        ...mapState(['sateliteView'])
    },
    methods: {
        ...mapActions(['setSateliteView']),
        toggleKey() {
            this.$store.dispatch('setSateliteView', !this.sateliteView)
        }
    }
};
</script>
