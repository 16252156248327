<template>
  <div class="site-view">

    <section class="site-top-banner">
      <div class="hero">
        <div class="container">
          <div class="row">
            <div class="col-flex">
              <header :class="{ 'main-nav--open': navOpen }">
                <div class="header">
                  <Navigation/>
                  <div>
                    <p class="sub-logo">#CONNECTSHAWS</p>
                    <a href="#" class="menu" @click="navOpen = !navOpen">
                      <div id="nav-burger">
                        <div class="burger-box"><span></span><span></span><span></span></div>
                      </div>
                    </a>
                    <div class="logo" v-html="appName"></div>
                    <div id="nav-user">

                    </div>
                  </div>
                </div>
              </header>
              <router-link :to="'/'">
                <img class="main-sub-logo" src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg"/>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-cards-2col site-bg--purple">
      <div class="container">

        <div class="row-flex">

          <div class="col-flex ">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img class="logo" src="@/assets/images/pollokshaws/Shaws-Logo.svg" alt="">
                </div>
                <div class="cp_card--text">
                  <h2>WHO WE ARE</h2>
                  <h3>We are the Connecting Pollokshaws Project Team and we are all passionate about making Pollokshaws
                    a great place to live, work and play.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="site-cards-2col">
      <div class="container">

        <div class="row-flex">

          <div class="col-flex col-flex-border">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img src="@/assets/images/pollokshaws/robert.png" alt="">
                </div>
                <div class="cp_card--text">
                  <p class="name">ROBERT <br/> MCKINNON</p>
                  <p class="highlight"><span>Project Champion</span></p>
                  <p class="profile">Service Manager, Pollokshaws After School Service</p>
                  <p class="text">
                    Hi, I’m Robert McKinnon and I’ve been the Service Manager at Pollokshaws After School Service for 8
                    years. I love football, particularly Arsenal & spending time with my wife & baby. The Southside of
                    Glasgow has been a big part of my life. I’ve been envious of other parts of the city that have been
                    regenerated and have created strong community values. I believe this project will hugely benefit the
                    people of Pollokshaws by creating a more sustainable, healthy and attractive neighbourhood for
                    current and future generations to live and grow up in.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-flex col-flex-border">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img src="@/assets/images/pollokshaws/Amy.png" alt="">
                </div>
                <div class="cp_card--text">
                  <p class="name">Amy <br/>Wimpenny</p>
                  <p class="highlight"><span>Client Project Manager</span></p>
                  <p class="profile">Regeneration Co-ordinator, Wheatley Group</p>
                  <p class="text">
                    With a background in Architecture, Amy has 12 years’ experience in Regeneration, including delivery
                    of award winning public realm and infrastructure projects, community and arts led projects and
                    leveraging of grant funding. Amy has been involved in high profile, place based regeneration
                    programmes and leads on the Transforming Communities: Glasgow programme for Wheatley Group, working
                    closely in partnership with Glasgow City Council and the Scottish Government.
                  </p>
                </div>

              </div>
            </div>
          </div>

          <!--            <div class="col-flex col-flex-border">
                        <div class="wrapper">
                          <div class="cp_card">
                              <div class="cp_card&#45;&#45;image">
                              <img src="@/assets/images/pollokshaws/Dan.png" alt="">
                            </div>
                            <div class="cp_card&#45;&#45;text">
                              <p class="name">Dan <br/>Jeffs</p>
                               <p class="highlight"><span>Sponsor</span></p>
                              <p class="profile">Community Engagement and Urban Designer, Sustrans</p>
                              <p class="text">
                                Hi, I’m Dan and I’m an Urban Designer at Sustrans and have a strong background in community engagement. I am passionate about the impact active travel can have on the health and happiness of communities. I am excited to be part of this community driven project and see how it sets up a bright and active future for the Shaws.
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>-->

          <div class="col-flex col-flex-border">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img src="@/assets/images/pollokshaws/ujwala.png" alt="">
                </div>
                <div class="cp_card--text">
                  <p class="name">Ujwala<br/>Fernandes</p>
                  <p class="highlight"><span>Project Manager</span></p>
                  <p class="profile">Associate Director, Atkins</p>
                  <p class="text">
                    Hello, I’m Ujwala and I am an Associate Director in the Architecture & Masterplanning team at
                    Atkins. I am a Landscape Architect by profession and I am very excited to be leading a
                    multi-disciplinary design team as the Project Manager on this project. I have extensive experience
                    in urban regeneration, landscape and active travel design and community and stakeholder engagement.
                    I live locally with my family and am very familiar with Pollokshaws. I am delighted to be involved
                    in this project that aims to transform Pollokshaws into an attractive, well connected and
                    people-friendly neighbourhood.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-flex col-flex-border">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img src="@/assets/images/pollokshaws/victoria.png" alt="">
                </div>
                <div class="cp_card--text">
                  <p class="name">VICTORIA <br/> MCLEAN</p>
                  <p class="highlight"><span>School Project Champion</span></p>
                  <p class="profile">Teacher, St Conval’s Primary School</p>
                  <p class="text">
                    Hi, my name is Victoria MacLean and I am a class teacher at St Conval’s Primary school. Last year I
                    was involved in the planning and teaching of the Health & Wellbeing curriculum. I am very excited to
                    be the St Conval’s champion of the ‘Connecting Pollokshaws’ project. I believe this project has the
                    potential to benefit children in many ways by creating a healthier, safer more attractive
                    neighbourhood for them to grow up in and enjoy.
                  </p>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="site-logos site-logos--blue">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex col-flex-grow">
            <img src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg" alt="">
          </div>
          <div class="col-flex">
            <a href="https://www.facebook.com/cpollokshaws" target="_blank">
              <img src="@/assets/images/pollokshaws/Facebook2.png" alt="">
            </a>
          </div>
          <div class="col-flex">
            <a href="https://twitter.com/CPollokshaws" target="_blank">
              <img src="@/assets/images/pollokshaws/Twitter2.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="site-logos site-logos--footer">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex">
            <img class="shaw-logo" src="@/assets/images/pollokshaws/Shaws-Logo-Blue.svg" alt="">
          </div>
          <div class="col-flex col-flex-grow">
            <div class="logo-wrapper">
              <div class="logo-row">
                <div class="logo-col">
                  <a href="https://www.wheatley-group.com/" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/WHG-Logo-CMYK-coated.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.sustrans.org.uk/" target="_blank">
                    <img src="@/assets/images/pollokshaws/logos/sustrans-logo.jpg" alt="">
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.transport.gov.scot/ " target="_blank">
                    <img src="@/assets/images/pollokshaws/logos/transport-scotland-logo.jpg" alt="">
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.facebook.com/Pollokshawsafterschoolservice/" target="_blank">
                    <img src="@/assets/images/pollokshaws/logos/pass-logo.jpg" alt="">
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://blogs.glowscotland.org.uk/gc/stconvalsprimaryschool/" target="_blank">
                    <img src="@/assets/images/pollokshaws/logos/st-convals-logo.jpg" alt="">
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://glasgow.gov.uk/home" target="_blank">
                    <img src="@/assets/images/pollokshaws/logos/glasgow-city-council-logo.jpg" alt="">
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.atkinsglobal.com" target="_blank">
                    <img src="@/assets/images/pollokshaws/logos/atkins-logo.jpg" alt="">
                  </a>
                </div>
              </div>

            </div>
          </div>
          <p class="copyright">Copyright Ⓒ Connecting Pollokshaws 2022 | All rights reserved | Site by Atkins Creative
            Design</p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Apicall from "@/mixins/Apicall";
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import {mapState, mapGetters} from "vuex";
import SiteFooter from "@/components/customPages/components/footer.vue";
import SiteNav from "@/components/customPages/components/siteNav.vue";
import Navigation from "@/components/customPages/navigation.vue";
import _ from "lodash";

const Stickyfill = require("stickyfilljs");

export default {
  name: "homePage",
  components: {
    SiteFooter, SiteNav, Navigation
  },
  data() {
    return {
      navOpen: false
    };
  },
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
  },
  async mounted() {

  },
  methods: {},
  watch: {
    $route(to, from) {
      this.navOpen = false
      this.activeInstance = to.params.root;
    }
  }
};
</script>
