<template>
  <section id="header-panel">
    
  <div class="nav">
    <div class="nav-header">
      <div class="nav-title" v-if="navigation.navDetails">
        <router-link :to="'/site/homePage'">
         <img class="logo" src="@/assets/images/pollokshaws/ConnectingLogo.svg">
        </router-link>
        <!-- <img class="logo" :src="'/img/'+navigation.navDetails.name+'/'+navigation.navDetails.logo" :alt="navigation.siteTitle" /> -->
      </div>
      
    </div>
    <!-- <ul class="navbar-nav">
      <li class="title-logo"><h1>{{navigation.siteTitle}}</h1></li>  
    </ul> -->
    <div class="nav-btn">
      <button @click.prevent="toggleNav = !toggleNav">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  
    <div class="nav-links lang-btns" v-if="navigation && navigation.navDetails" :class="{'mobile-open-nav': toggleNav}" >

        <p class="mobile-name">Logged in: {{userDisplayName}}</p>

        <router-link v-for="item in publicMenuOptions" :key="item.index" :to="'/'+item.link" :class="{active: activeInstance == item.link  ? true : false}" >
          <div class="alignLogo">{{item.displayName}}</div> <img  class="alignHeaderArrow" src="@/assets/images/pollokshaws/backtohome.svg">
          <span v-if="item.counter" class="counter">
            ({{count}})</span>
        </router-link>

        <!-- <router-link  v-for="item in rootTypes" :key="item.index" :to="(item.renderTemplate != undefined ? 'template' + '/' + item.type : '' + item.type + '/')" :class="{active: activeInstance == item.type  ? true : false}" >{{item.displayName}}</router-link> -->

        <a class="dropdown-trigger header-dropdown user-btn" href="#" data-target="dropdown1" @click.prevent="toggleDropdown()" v-if="user.role=='Admin' && username">
          <i v-if="username" class="material-icons">person</i>
        </a>
        <ul id="dropdown1" class="dropdown-content user-menu" aria-label="submenu" v-if="showDropdown">
          <li class="user-info" :title="'Logged in as: ' + userDisplayName + ' (' + username + ')'">
            <span><i class="material-icons">person</i> {{ userDisplayName }}</span>
          </li>
          <li class="divider" tabindex="-1"></li>
          <li v-for="(menu, index) in adminMenuOptions" :key="index">
            <router-link :to="'/'+menu.link">{{menu.displayName}}</router-link>
          </li>
          <li class="divider" tabindex="-1"></li>
          <li v-if="navigation.navDetails.user.login">
            <a href="#" @click.prevent="logout" :title="'Current user ' + username + ' Click to logout'"><i class="material-icons">exit_to_app</i> Logout</a>
          </li>
        </ul>

        <div class="mobile-admin">
          <div class="admin-opts">
            <div class="link"  v-for="item in adminMenuOptions" :key="item.index">
              <router-link :to="'/'+item.link" :class="{active: activeInstance == item.link  ? true : false}" >{{item.displayName}}</router-link>
            </div>
          </div>
          <div v-if="navigation.navDetails.user.login" class="link">
              <a href="#" @click.prevent="logout" :title="'Current user ' + username + ' Click to logout'"><i class="material-icons">exit_to_app</i> Logout</a>
          </div>
        </div>
    </div>
     
  </div>
    
    
  </section>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState,mapActions,mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import { store } from "../../store.js";
export default {
  name: "HomesEnglandHeader",
  components: {
    Branding
  },
  data() {
    const user = firebase.auth().currentUser;

    return {
      showDropdown:false,
      hideButton: true,
      username: "",
      userDisplayName: "",
      userRole: "",
      appTitle: Settings.appTitle,
      activeInstance: this.$route.params.root,
      showHelp: Settings.showHelp,
      showUser: Settings.showUser,
      logo: Logo,
      showMenu: false,
      userMenuDropDownInstance: null,
      toggleNav: false,
      count:0
      
    };
  },
  created: function() {
 
},
  mounted() {
    this.$store.state.navigation = this.setNavigation
    
    let user = firebase.auth().currentUser;

    if (user) {
      this.username = user.email;
      this.userDisplayName = user.displayName;
      this.userRole = user.role
    }
    
    let elems = document.querySelectorAll(".dropdown-trigger");
    this.userMenuDropDownInstance = M.Dropdown.init(elems, {});
  },
  beforeCreate() {
         this.$store.dispatch('setNavigation');
    },
  //    beforeDestroy () {
  //   document.removeEventListener('click',this.close)
  // },
  methods: {
    ...mapActions(['setNavigation','setFeedbackCount']),
    // close (e) {
    //   setTimeout(() => {
    //     this.state = false;
    // }, 200);
    // },
    toggleDropdown () {
      if(this.showDropdown == true){
        this.showDropdown=false
      }else{
          this.showDropdown= true
      }
    },
    
    add: function() {
      this.$router.push("/add");
    },
    
    logout: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("setUserData", "");
          this.$store.commit("setCurrentInstance", "");
          this.$router.push("/login");
        })
        .catch(function(error) {
          alert("Ops " + error.message);
        });
    }
  },
  computed: {
    ...mapState(["feedbackData",'feedbackCountNew']),
    ...mapGetters(["user", "allEntityTypes","navigation"]),
   feedbackCountNew(){
     let count=0

     if(this.feedbackData.length != 0){
            Object.keys(this.feedbackData).forEach((key, index) => {
                  if(this.feedbackData[key].group.show == true){
                      count++;
                  };
              });
              this.count=count-1
      }else{
        if(feedbackCountNew !=undefined){
          this.count=this.feedbackCountNew
        }
        
      }
     
   },
    
    rootTypes: function() {
      if (this.allEntityTypes && this.user) {
        let rootTypes = this.allEntityTypes.filter(item => {
          return item.parent.length == 0;
        });
        let foundTypes = [];

        for (let item of rootTypes) {
          if (item.requiredRole == "*") {
            foundTypes.push(item);
          } else {
            if (item.requiredRole.indexOf(this.user.role) != -1 ) {
              foundTypes.push(item);
            }
          }
        }

        return foundTypes;
      }
    },
    adminMenuOptions() {
      if(this.user) {
        let adminMenu = _.filter(this.navigation.navDetails.menuItems, ['requiredRole', 'Admin']);
        let orderedAdminMenu = _.orderBy(adminMenu, ['order'], ['asc']);
        if(this.user.role == 'Admin') {
          return orderedAdminMenu 
        }
        else {
          return [];
        }
      } else {
        return [];
      }
    },
    publicMenuOptions() {
      let publicMenu = _.filter(this.navigation.navDetails.menuItems, ['requiredRole', '*']);
      let orderedPublicMenu = _.orderBy(publicMenu, ['order'], ['asc']);
      return orderedPublicMenu;
    }
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
      this.toggleNav = false;
    },
    
  //   '$store.state.feedbackData': function() {
  //     let feedbackData=this.$store.state.feedbackData;
  //     let count=0
  //     if(feedbackData.length!=0){
  //       Object.keys(feedbackData).forEach((key, index) => {
  //           if(feedbackData[key].group.show == true){
  //             count++;
  //           };
  //       });
  //     }else{
  //       count=feedbackData.length;
  //     }
  //     this.setFeedbackCount((count-1))
  //     this.count=(count-1)
    
  // }
  }
};
</script>
