<template>
  <div class="site-view" v-if="pageData">
    <section class="site-text site-topborder">
        <div class="container">
            <div class="row mb-0">
                <div class="col l12">
                    <h1>{{ pageData.properties.pageTitle }}</h1>
                    <div v-html="pageData.properties.pageDescription"></div>
                </div>
            </div>
        </div>
    </section>

    <SiteAccordion :data="schemeItems" :headerField="'schemeHeading'" :contentField="'schemeContent'" />

    <SiteFooter />
  
  </div>
</template>

<script>
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import { mapState,mapGetters } from "vuex";
import Logo from "@/assets/images/heathrow/logo.png";
import Branding from "@/components/Branding";
import SiteAccordion from "@/components/customPages/components/accordion.vue";
import SiteFooter from "@/components/customPages/components/footer.vue";


export default {
  name: "schemesPage",
  components: {
    Branding, SiteAccordion, SiteFooter
  },
  methods: {},
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
    pageData() {
      if(this.$route.params.id != undefined) {
        return this.allEntities.find(item => item.docId == this.$route.params.id)
      } else {
        return this.allEntities.find(item => item.type == this.$route.params.templateName)
      }
    },
    schemeItems() {
      if(this.pageData) {
        const vm = this;
        let array = vm.pageData.children.schemeitem;
        return array.map(function(docID) {
          return vm.allEntities.find(item => item.docId == docID);
        })
      } else {
        return [];
      }
    }
  },
  watch: {
    $route(to, from) {
      this.activeInstance = to.params.root;
    }
  }
};
</script>
