<template>
  <div class="site-view">
    <section class="site-top-banner">
      <div class="hero">
        <div class="container">
          <div class="row">
            <div class="col-flex">
              <header :class="{ 'main-nav--open': navOpen }">
                <div class="header">
                  <Navigation/>
                  <div>
                    <p class="sub-logo">#CONNECTSHAWS</p>
                    <a href="#" class="menu" @click="navOpen = !navOpen">
                      <div id="nav-burger">
                        <div class="burger-box">
                          <span></span><span></span><span></span>
                        </div>
                      </div>
                    </a>
                    <div class="logo" v-html="appName"></div>
                  </div>
                </div>
              </header>
              <router-link :to="'/'">
                <img
                    class="main-sub-logo"
                    src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg"
                />
              </router-link>
              <!-- <img class="main-logo" src="@/assets/images/pollokshaws/Shaws-Logo.svg" alt="">  -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-cards-2col site-bg--purple">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex">
            <div class="wrapper">
              <div class="cp_card">
                <div class="cp_card--image">
                  <img
                      class="logo"
                      src="@/assets/images/pollokshaws/Shaws-Logo.svg"
                      alt=""
                  />
                </div>
                <div class="cp_card--text">
                  <h2>The Project<br/>Timeline</h2>
                  <h3>
                    See below a description of the tasks we will undertake at each of the different project stages and
                    how you can be involved.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="site-cards-5">
      <div class="container">
        <div class="item">
          <div class="col-1 init">
            <div class="stage">Stage 1</div>
            <p class="date tint-blue">July 2020 - <br/>February 2021</p>
          </div>
          <div class="col-2 col-divider"></div>
          <div class="col-3 init">
            <div class="desc">Preparation &amp; Project Brief</div>
            <p class="text">
              In this stage we asked you to tell us your thoughts about your neighbourhood which helped us to define
              priorities for improvements and establish the project scope and objectives. We carried out an analysis of
              the Pollokshaws area and the wider context and started to identify and explore opportunities for physical
              improvements that would most benefit the people of Pollokshaws from a local as well as strategic
              perspective.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="site-cards-5">
      <div class="container">
        <div class="item">
          <div class="col-1">
            <div class="stage tint-green">Stage 2</div>
            <p class="date ">June 2022 - <br/>September 2022</p>
          </div>
          <div class="col-2 col-divider tint-green"></div>
          <div class="col-3">
            <div class="desc tint-green">Concept Design</div>
            <p class="text">
              In this stage we will work closely with various stakeholder groups such as Glasgow City Council to further
              develop our initial thoughts and ideas into concept design proposals which will focus on the heart of
              Pollokshaws. We will ask you to tell us what you would like to see in Toon House Square, Ashtree Park and
              surrounding streets and spaces and we will develop a visionary masterplan for the heart of Pollokshaws
              that responds to your needs and aspirations for your neighbourhood. You will have the opportunity to view
              and comment on the masterplan proposals before these are then taken forward and developed in the next
              stage of the project.
            </p>
            <!--            <p class="text">
                          These designs will create a substantial transformation which aligns with the ambition and vision for the
                          whole of Pollokshaws area discussed in Stage 1. You will have two ‘in person’ public consultation
                          opportunities to comment on the concept designs and talk to the team. The first event will introduce ideas
                          for Ashtree Park, Toon House Square and the surrounding streets and paths which will spark off your own
                          ideas for what you want introduced into the heart of Pollokshaws. We invite you to complete the short
                          online survey available on this website or a paper survey which will be available from Naomh (T: 078111
                          30507). We will display consultation panels in Pollokshaws Library for you to view before and after the
                          first public consultation event. We will then develop these ideas further to form the final concept
                          designs for these spaces. You will then be invited to comment on these in August 2022. Follow the project
                          on Facebook
                          <a href="https://www.facebook.com/cpollokshaws/" target="_blank">@cpollokshaws</a>
                          and on Twitter #connectpollokshaws.
                        </p>-->
          </div>
        </div>
      </div>
    </section>

    <section class="site-cards-5">
      <div class="container">
        <div class="item">
          <div class="col-1">
            <div class="stage tint-yellow">Stage 3</div>
            <p class="date tint-purple">November 2022 - <br/>December 2022</p>
          </div>
          <div class="col-2 col-divider tint-yellow"></div>
          <div class="col-3">
            <div class="desc tint-yellow">Developed Design</div>
            <p class="text">
              In this Stage we will work with key stakeholders to further
              develop our design proposals to create a technical design
              package for review and approval from Glasgow City Council
              and other stakeholders.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="site-cards-5">
      <div class="container">
        <div class="item">
          <div class="col-1">
            <div class="stage tint-purple">Stage 4</div>
            <p class="date tint-green">January 2023 - <br/>March 2023</p>
          </div>
          <div class="col-2 col-divider tint-purple"></div>
          <div class="col-3">
            <div class="desc tint-purple">Technical Design</div>
            <p class="text">
              In this stage we will prepare all required drawings and
              information to enable our design proposals to be built on
              the ground. We will work with Sustrans, Glasgow City Council
              and other stakeholders to acquire funding for the different
              elements of the construction works and seek to acquire a
              suitable contractor to undertake the delivery of the
              construction works in a phased manner.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="site-cards-5">
      <div class="container">
        <div class="item">
          <div class="col-1">
            <div class="stage tint-orange">Stage 5</div>
          </div>
          <div class="col-2 col-divider tint-orange"></div>
          <div class="col-3">
            <div class="desc tint-orange">Construction</div>
            <p class="text">
              In this very exciting phase of construction you will be able
              to witness the transformation of your neighbourhood! We
              along with the appointed contractor will continue to work
              with the community and stakeholders to ensure the
              construction is well managed and causes minimum disruption.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="site-cards-5">
      <div class="container">
        <div class="item">
          <div class="col-1">
            <div class="stage tint-pink">Stage 6</div>
          </div>
          <div class="col-2 col-divider tint-pink"></div>
          <div class="col-3">
            <div class="desc tint-pink">Completion</div>
            <p class="text">
              This phase will signal the completion of the construction
              works and will enable current and future generations of the
              local community to enjoy living, working and going to school
              in Pollokshaws for years to come!
            </p>
          </div>
        </div>
      </div>
    </section>


    <section class="site-logos site-logos--blue">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex col-flex-grow">
            <img
                src="@/assets/images/pollokshaws/ConnectingLogoWhite.svg"
                alt=""
            />
          </div>
          <div class="col-flex">
            <a href="https://www.facebook.com/cpollokshaws" target="_blank">
              <img src="@/assets/images/pollokshaws/Facebook2.png" alt=""/>
            </a>
          </div>
          <div class="col-flex">
            <a href="https://twitter.com/CPollokshaws" target="_blank">
              <img src="@/assets/images/pollokshaws/Twitter2.png" alt=""/>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="site-logos site-logos--footer">
      <div class="container">
        <div class="row-flex">
          <div class="col-flex">
            <img
                class="shaw-logo"
                src="@/assets/images/pollokshaws/Shaws-Logo-Blue.svg"
                alt=""
            />
          </div>
          <div class="col-flex col-flex-grow">
            <div class="logo-wrapper">
              <div class="logo-row">
                <div class="logo-col">
                  <a href="https://www.wheatley-group.com/" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/WHG-Logo-CMYK-coated.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.sustrans.org.uk/" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/sustrans-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.transport.gov.scot/ " target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/transport-scotland-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a
                      href="https://www.facebook.com/Pollokshawsafterschoolservice/"
                      target="_blank"
                  >
                    <img
                        src="@/assets/images/pollokshaws/logos/pass-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a
                      href="https://blogs.glowscotland.org.uk/gc/stconvalsprimaryschool/"
                      target="_blank"
                  >
                    <img
                        src="@/assets/images/pollokshaws/logos/st-convals-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://glasgow.gov.uk/home" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/glasgow-city-council-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
                <div class="logo-col">
                  <a href="https://www.atkinsglobal.com" target="_blank">
                    <img
                        src="@/assets/images/pollokshaws/logos/atkins-logo.jpg"
                        alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p class="copyright">
            Copyright Ⓒ Connecting Pollokshaws 2022 | All rights reserved | Site
            by Atkins Creative Design
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Apicall from "@/mixins/Apicall";
import firebase from "firebase";
import Settings from "@/mixins/AppSettings.js";
import {mapState, mapGetters} from "vuex";
import SiteFooter from "@/components/customPages/components/footer.vue";
import SiteNav from "@/components/customPages/components/siteNav.vue";
import Navigation from "@/components/customPages/navigation.vue";
import _ from "lodash";

const Stickyfill = require("stickyfilljs");

export default {
  name: "homePage",
  components: {
    SiteFooter,
    SiteNav,
    Navigation,
  },
  data() {
    return {
      navOpen: false,
    };
  },
  computed: {
    ...mapState(["entities", "entityTypes"]),
    ...mapGetters(["user", "allEntityTypes", "allEntities"]),
  },
  async mounted() {
  },
  methods: {},
  watch: {
    $route(to, from) {
      this.navOpen = false;
      this.activeInstance = to.params.root;
    },
  },
};
</script>
